export const schools = [
    {
        id: 6,
        name: {
            ar: "الصف السادس الإبتدائي",
            en: "الصف السادس الإبتدائي"
        },
        param: "6primary",
        type: 0
    }
]