import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserAnswer } from "../store/actions";
import { ResizeImage } from "./resizeImage";
import config from "../sql/config.json";
import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";

export const EssayModal = (props) => {
  const params = useParams();
  const exams = props.exam
  const [score, setScore] = useState(0);
  const dispatch = useDispatch();
  const {
    essayModalBody,
    essayModalButton,
    essayModalTitle,
    essayModalUser,
    userAnswer,
    currentQues,
  } = useSelector((state) => state.userAppReducer);
  const [imageLoaded, setiImageLoaded] = useState(true);

  const aplayScore = (number) => {
    let e =
      number > essayModalBody.QuesScore ? essayModalBody.QuesScore : number;
    setScore(
      number > essayModalBody.QuesScore ? essayModalBody.QuesScore : number
    );
    // @ts-ignore
    if (essayModalBody.parent === true) {
      if (!userAnswer[currentQues]) {
        userAnswer[currentQues] = [];
        userAnswer[currentQues][essayModalBody.key] = [];
      }
      userAnswer[currentQues][essayModalBody.key] = [
        {
          index: currentQues,
          answer: essayModalUser,
          right: 1,
          type: "essay",
          userScore: parseInt(e),
          score: parseInt(essayModalBody.QuesScore),
          status: true,
        },
      ];
    } else {
      userAnswer[currentQues] = {
        index: currentQues,
        answer: userAnswer[currentQues]
          ? userAnswer[currentQues].answer
          : essayModalUser,
        right: 1,
        type: "essay",
        userScore: parseInt(e),
        score: parseInt(essayModalBody.QuesScore),
        status: true,
      };
    }
    // @ts-ignore
    dispatch(setUserAnswer(userAnswer));
  };
  useEffect(() => {
    window.imagesCount = 0;
    checkImageCount();
  }, [currentQues]);
  const checkImageCount = () => {
    $("#checkImageCount").html(
      essayModalBody && essayModalBody.QuesShowAns1
        ? essayModalBody.QuesShowAns1.txt
        : ""
    );
    var img = $("img"); // استخراج جميع الصور في الصفحة
    var images = $("#checkImageCount").find("img");
    if (images.length > 0) {
      img.on("load", function () {
        if (
          this.complete &&
          typeof this.naturalWidth !== "undefined" &&
          this.naturalWidth > 0
        ) {
          window.imagesCount = window.imagesCount + 1;
          if (window.imagesCount >= images.length || images.length == 0) {
            setTimeout(() => {
              setiImageLoaded(false);
            }, 500);
          }
        }
      });
    } else {
      setiImageLoaded(false);
    }
  };

  if (props.active === true) {
    return (
      <div className="w-screen h-screen bg-[#001670] bg-opacity-25 fixed top-0 left-0 right-0 flex justify-center items-center align-middle content-center self-center z-50 md:max-w-[875px] md:m-auto overflow-hidden">
        <div className="bg-white  max-h-[95vh] overflow-hidden rounded-3xl w-11/12 h-fit flex flex-col  gap-4 justify-start">
          <div className="w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start">
            <div className="flex flex-row justify-between">
              <h1 className="w-full flex justify-start font-semibold text-left rtl:text-right text-md">
                {essayModalTitle}
              </h1>
            </div>
            <div className="flex h-full text-left rtl:text-right border rounded-md p-1">
              <ResizeImage
                size="rem"
                type="modelAnswer"
                text={essayModalBody.QuesShowAns1.txt}
              />
            </div>
            <h1 className="w-full flex justify-start font-semibold text-left rtl:text-right text-md">
              {config.config[exams.lang]?.YourAnswer}
            </h1>
            <div className="w-full bg-gray-200 text-gray-600 border rounded-lg p-2 border-primary opacity-40 border-opacity-30">
              {userAnswer[currentQues] && userAnswer[currentQues].answer
                ? userAnswer[currentQues].answer
                : config.config[props.lang].noEssayAnswerUser}
            </div>
            <div className="shrink-0 h-10 flex flex-row rtl:flex-row-reverse justify-between gap-5">
              {essayModalButton.map((button, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={button.action}
                  className={`${
                    button.color +
                    ` ` +
                    button.border +
                    ` ` +
                    button.borderColor +
                    ` ` +
                    button.background
                  } select-none w-32 text-center py-2 px-4 rounded-full m-auto`}
                >
                  {button.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};
