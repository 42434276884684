import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { BackNavBar, NavBar } from '../components';
import $ from "jquery";
import AWS from 'aws-sdk';
import * as XLSX from "xlsx";

export const Users = (props) => {
    const { lang } = useSelector(state => state.userAppReducer);
    const [usersList, setUsersList] = useState([])
    const [usersMain, setUsersMain] = useState([])
    const [bg,setBg] = useState('odd:bg-gray-100 even:bg-white')
    const [user, setUser] = useState([])
    const [lastKey, setLastKey] = useState(null)
    const [modal, setModal] = useState(false)
    const [sortBy, setSortBy] = useState(['date','Asce'])
    useEffect(() => {
        GetUser()
    }, [])

    AWS.config.update({
        accessKeyId: "AKIA452FOHXXJI3KVTFY",
        secretAccessKey: "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",
        region: "eu-west-1",
    });
    const documentClient = new AWS.DynamoDB.DocumentClient();
    const GetUser = () => {
        const usersParams = {
            TableName: "exams",
            Limit: 1000,
        };
        documentClient.scan(usersParams,
            function (err, ee) {
                if (err) {
                } else {
                    setUsersList(ee.Items)
                    // console.log(ee);
                    const lastKeyObject = ee.LastEvaluatedKey;
                    if (lastKeyObject) {
                        setLastKey(lastKeyObject)
                    } else {
                        setLastKey(null)
                    }

                    var height = document.body.scrollHeight;
                    window.scroll(0, height);
                }
            }
        );
    };
    const loopInDynamoDB = () => {
        const usersParams = {
            TableName: "exams",
            ExclusiveStartKey: lastKey,
            Limit: 1000
        };
        documentClient.scan(usersParams,
            function (err, ee) {
                if (err) {
                    console.log(err);
                } else {
                    setUsersList([...usersList, ...ee.Items])
                    const lastKeyObject_ = ee.LastEvaluatedKey;
                    if (lastKeyObject_) {
                        setLastKey(lastKeyObject_)
                    }else{
                        console.log(usersList.filter((obj, index, self) =>index === self.findIndex((o) => o.id === obj.id)));
                    }
                    var height = document.body.scrollHeight;
                    window.scroll(0, height);
                }
            }
        );
    }
    const DeleteUser = (id) => {
        const confirm = window.confirm("Delete user alert!\nDo you want to delete this user ?");
        if(confirm===true){
            documentClient.delete(
                {
                    TableName: "exams",
                    Key: { id: `${id}` },
                },
                function (err, ee) {
                    if (err) {
                        console.log(err);
                    } else {
                        GetUser()
                    }
                }
            );
        }
    };
    const CheckUser = () => {
        setUsersMain(usersList)
        if (lastKey != null) {
            loopInDynamoDB(lastKey)
        } else {
            // GetUser()
        }
    };
    const ClearScore = (id) => {
        var dataObject = {
            TableName: 'exams',
            Key: { id: `${id}` },
            UpdateExpression: 'set #scores = :myScore',
            ExpressionAttributeNames: {
                '#scores': 'scores'
            },
            ExpressionAttributeValues: {
                ':myScore': [],
            }
        };
        const confirm = window.confirm("Clear score alert!\nDo you want to clear score for this user ?");
        if(confirm===true){
            documentClient.update(dataObject, function (err, data) {
                if (err) console.log(err);
                else GetUser();
            });
        }
    }
    const showDetails = (user) => {
        // console.log(user);
        setUser(user)
        setModal(!modal)
    }
    const ActiveModale = () => {
        setUser([])
        setModal(!modal)
    }
    const getExamData = (id) => {
        const ae = require("../data/exams/" + id + ".json");
        return ae.title
    }
    const secondsToHms = (duration) => {
        const pad = (n) => n < 10 ? `0${n}` : n;
        const h = Math.floor(duration / 3600);
        const m = Math.floor(duration / 60) - (h * 60);
        const s = Math.floor(duration - (h * 3600 )- (m * 60));
        return h + ":" + pad(m) + ":" + pad(s)
    }
    const getScores = (scores, id) => {
        const exams = require("../data/exams/" + id + ".json");
        const score = userScore(exams, scores)
        return score
    }
    const userScore = (exams, userAnswer) => {
        let score = 0
        exams.questions.map((item, index) => {
            if (item.QuesType === "mcq") {
                let a = userAnswer.filter(e => e.index === index);
                score = score + parseInt(((a[0] ? a[0].answer : '') === item.QuesRightAns) ? item.QuesScore : 0)
            } else if (item.QuesType === "essay") {
                let a = userAnswer.filter(e => e.index === index);
                score = score + parseInt((a[0] && a[0].answer && typeof a[0].answer == "number") ? a[0].answer : item.QuesScore)
            } else {
                let a = userAnswer.filter(e => e.parentIndex === index);
                if (a[0]) {
                    // score = score + parseInt((item.list[a[0].index].QuesRightAns === a[0].answer) ? item.list[a[0].index].QuesScore : 0)
                    if (item.QuesType === "mcq") {
                        // let a = userAnswer.filter(e => e.index === index);
                        score = score + parseInt(((a[0] ? a[0].answer : '') === item.QuesRightAns) ? item.QuesScore : 0)
                    } else if (item.QuesType === "essay") {
                        // let a = userAnswer.filter(e => e.index === index);
                        score = score + parseInt((a[0] && a[0].answer && typeof a[0].answer == "number") ? a[0].answer : item.QuesScore)
                    }
                }
            }
        })
        return score
    }
    const Export = () => {
        const data = []
        usersList.map((user,index)=>{
            data.push({
                id:user.id,
                date: user.date,
                exams:user.scores.length,
                scores: user.scores
            })
        })
        // console.log(data);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([['Uuid','Register date','Exams number'],...data.map((row) => [row.id, row.date, row.exams])]);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
        data.map((item,index)=>{
            const scoresWs = XLSX.utils.json_to_sheet([['Subject name','date','time','score'],...item.scores.map((row) => [getExamData(row.id), row.timestamp, secondsToHms(parseInt(row.time), getScores(row.answers, row.id))])]);
            scoresWs["A1"].v = "id";
            XLSX.utils.book_append_sheet(workbook, scoresWs, 'score_'+index.toString());
        })
        XLSX.writeFile(workbook, "Users.xlsx");
    }
    const UserRender = ({ user, index }) => {
        let IsNew = usersMain.filter(e=>e.id===user.id)[0]
        var activeBG = bg;
        if(!IsNew){
            // console.log(IsNew[0]);
            activeBG = 'bg-green-100'
            setTimeout(() => {
                $('.activeNew').removeClass('bg-green-100')
                $('.activeNew').addClass(bg)
            }, 10000);
        }else{
            activeBG = bg
        }
        return (
            <div key={index} className={`flex flex-col sm:flex-row justify-start gap-2 border  shadow-sm activeNew ${activeBG} items-center divide-y sm:divide-x sm:divide-y-0 relative`}>
                <div className="w-full h-full flex px-2 text-sm flex-row gap-3 justify-center items-center divide-x">
                    <div className="w-fit shrink-0">{index+1}</div>
                    <div className="w-full p-1 h-full flex flex-col">
                        <div className="text-gray-300 text-sm">{user.id}</div>
                        <div className="text-gray-300 text-sm">{user.date}</div>
                    </div>
                </div>
                <div className="w-full sm:w-[250px] h-full flex p-2 text-sm flex-row gap-3 justify-center items-center">
                    <span className="flex w-fit">Submitted Exams</span>
                    <span className='w-fit flex text-blue-500'>{(user.scores) ? user.scores.length : 0}</span>
                </div>
                <div className="w-full sm:w-fit flex flex-row justify-between p-2 gap-3 shrink-0 text-[12px]">
                    <button onClick={() => showDetails(user)} className=" bg-blue-500 flex text-white p-2 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </button>
                    <button onClick={() => ClearScore(user.id)} className=" bg-yellow-500 flex text-white p-2 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
                        </svg>
                    </button>
                    <button onClick={() => DeleteUser(user.id)} className="bg-red-500 flex text-white p-2 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                </div>
            </div>
        )
    }

    // useEffect(() => {
    //     const data = require('../data/data.json')
    //     console.log();
    // }, [])


    return (
        <div className="flex flex-col justify-start h-full min-h-screen w-full p-0 m-0  md:m-auto overflow-hidden relative">
            <NavBar menu={true} qr={false} back={false} title={true} titleText="المستخدمين" />

            <div className="p-3 flex flex-col w-full">

                <div className="flex w-full h-fit shrink-0  p-2">
                    <button onClick={() => GetUser()} className="bg-green-500 flex text-white p-3 py-2 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </button>
                    <div className="bg-white-500 flex text-green-500 p-3 py-2 rounded-lg">
                        {usersList.filter((obj, index, self) =>index === self.findIndex((o) => o.id === obj.id)).length}
                    </div>
                </div>
                {/* sort */}
                <div className="flex flex-col sm:flex-row justify-start gap-2 border text-white shadow-sm bg-gray-400 items-center divide-y sm:divide-x sm:divide-y-0 relative">
                    <div onClick={()=>{
                        setSortBy(['date',sortBy[1]=='Asce'?'Dsce':'Asce'])
                    }} className="w-full h-full flex px-2 text-sm flex-row gap-3 justify-center items-center divide-x">
                        <div className="w-fit shrink-0">#</div>
                        <div className="w-full p-1 h-full flex flex-col">
                            <div className="flex flex-row text-sm gap-2">
                                <div>uuid/date</div>
                                {
                                    sortBy[0]==='date'?$(
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        {
                                            (sortBy[1]=='Asce')?(
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
                                            ):(
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                                            )
                                        }
                                    </svg>
                                    ):''
                                }
                            </div>
                        </div>
                    </div>
                    <div onClick={()=>{
                        setSortBy(['score',sortBy[1]=='Asce'?'Dsce':'Asce'])
                    }} className="w-full sm:w-[250px] h-full flex p-2 text-sm flex-row gap-3 justify-center items-center">
                        <div className="flex flex-row text-sm gap-2">
                            <div>exams count</div>
                            {
                                    sortBy[0]==='score'?$(
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        {
                                            (sortBy[1]=='Asce')?(
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
                                            ):(
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                                            )
                                        }
                                    </svg>
                                    ):''
                                }
                        </div>
                    </div>
                    <div className="w-full sm:w-[160px] flex flex-row justify-between p-2 gap-3 shrink-0 text-[12px]">
                        <span className="flex w-fit">actions</span>
                    </div>
                </div>
                        {/* usersList.filter((obj, index, self) =>index === self.findIndex((o) => o.id === obj.id)).sort((a,b)=> 
                        (sortBy[0] === 'date')?
                            (sortBy[1]=='Asce')?(new Date(a.date) - new Date(b.date)):(new Date(b.date) - new Date(a.date))
                        : (sortBy[1]=='Asce')?(a.scores.length - b.scores.length ):(b.scores.length - a.scores.length ) 
                        ).map((user, index) => <UserRender key={index} index={index} user={user} />) */}
                <div className="flex w-full flex-row gap-3 h-fit justify-start  p-2">
                    <button onClick={() => GetUser()} className="bg-green-500 flex text-white p-3 py-2 rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                    </button>
                    <button className={`px-4 py-2 text-white ${lastKey?'bg-blue-600':'bg-gray-300'} rounded-lg`} onClick={() => CheckUser()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 5.25l-7.5 7.5-7.5-7.5m15 6l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>
                    <button className='px-4 py-2 text-white bg-blue-600 rounded-lg' onClick={() => Export()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                        </svg>
                    </button>
                    <div className="flex text-blue-600 p-3 py-2 ">
                        {usersList.filter((obj, index, self) =>index === self.findIndex((o) => o.id === obj.id)).length}
                    </div>
                </div>
            </div>
            {
                (modal === true) ? (
                    <div className="fixed w-screen h-screen bg-slate-700 bg-opacity-70 left-0 top-0 bottom-0 justify-center items-center">
                        <div className="w-10/12 h-[90vh] mt-16 bg-white rounded-lg shadow-lg p-4 m-auto flex flex-col">
                            {/* header */}
                            <div className="flex flex-row w-full justify-between border-b pb-2 bg-white">
                                <div>{user.id}</div>
                                <svg onClick={() => ActiveModale()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 fill-red-600 stroke-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div className='h-5'></div>
                            {/* scores */}
                            <div className="flex w-full flex-col bg-white overflow-auto">
                            {
                                user.scores.map((item, index) => (
                                    <div key={index} className="flex flex-col sm:flex-row w-full justify-between divide-y sm:divide-x border odd:bg-gray-100 even:bg-white">
                                        <div className="p-2 hidden sm:flex sm:w-10 shrink-0 text-center justify-center content-center items-center">{index + 1}</div>
                                        <div className="flex w-full p-2 flex-col">
                                            <div className="flex w-full">
                                                {
                                                    getExamData(item.id)
                                                }
                                            </div>
                                            <span className="flex w-full text-gray-400 text-[10px]">
                                                {
                                                    item.timestamp
                                                }
                                            </span>
                                        </div>
                                        <div className="py-2 w-full sm:w-[150px] px-2 shrink-0 text-center item-center justify-center content-center flex">{secondsToHms(parseInt(item.time))}</div>
                                        <div className="py-2 w-full sm:w-[60px] px-2 shrink-0 text-center item-center justify-center content-center flex">{getScores(item.answers, item.id)}</div>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    </div>
                ) : ''
            }
        </div>
    )

}