import React, { useState, useEffect, useRef } from "react";
import CanvasDraw from "react-canvas-draw";
import { BackNavBar } from "../../components";
import { setScreen } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function Canvas({ children, language }) {
  const [Brushcolor, setBrush] = useState("red");
  const [brush, setThick] = useState(1);
  const { screenValue } = useSelector((state) => state.userAppReducer);
  const [show, setShow] = useState(false);
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const handleClear = () => {
    canvasRef.current.clear();
  };

  const handleUndo = () => {
    canvasRef.current.undo();
  };

  const saveDrawing = () => {
    dispatch(setScreen(!screenValue));

    // // if (canvasRef.current) {
    // const drawingData = canvasRef.current.getSaveData();
    // console.log("Drawing data:", drawingData);
  };

  const [width, setWidth] = useState(
    window.innerWidth >= 875 ? 875 : window.innerWidth
  );
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth >= 875 ? 875 : window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "transparent",
      }}
      className={`w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      <BackNavBar canBack={false} language={language} />

      <div
        style={{
          position: "relative",
          backgroundColor: "transparent",
        }}
      >
        <div class="w-full top-0 absolute m-auto">
          {show && (
            <CanvasDraw
              style={{
                zIndex: 50,
                backgroundColor: "transparent",
                position: "absolute",
              }}
              ref={canvasRef}
              hideGrid={true}
              canvasWidth={width}
              canvasHeight={height}
              brushRadius={brush}
              brushColor={Brushcolor}
              catenaryColor="green"
              lazyRadius={1}
              //   saveDrawing
            />
          )}

          {children}
        </div>
      </div>
    </div>
  );
}

export default Canvas;
