import React, { useState, useEffect } from "react";
import { AnswerMCQ } from "./AnswerMCQ";
import { ResizeImage } from "./resizeImage";
import { Title } from "./Title";
import { useSelector, useDispatch } from "react-redux";
import {
  setModalBotton,
  setModalBody,
  setModal,
  setModalTitle,
  setBlur,
  setModalSubTitle,
} from "../store/actions";
import config from "../sql/config.json";
import $ from "jquery";

export const MCQ = (props) => {
  const dispatch = useDispatch();
  const {
    currentQues,
    fullReport,
    briefReport,
    blurExam,
    modal,
    examConfig,
    userAnswer,
  } = useSelector((state) => state.userAppReducer);
  const [imageLoaded, setiImageLoaded] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [current_is_answerd, setCurrent_is_answerd] = useState([]);
  useEffect(() => {
    setCurrent_is_answerd(
      userAnswer.filter((e) => e != null && e.index == currentQues)[0] || null
    );
    window.imagesCount = 0;
    setTimeout(() => {
      setLoaded(false);
    }, 50);
    checkImageCount();
  }, [loaded]);

  const checkImageCount = () => {
    $("#checkImageCount").html(
      props.question.QuesTxt +
        props.question.QuesAns[0].txt +
        props.question.QuesAns[1].txt +
        props.question.QuesAns[2].txt +
        props.question.QuesAns[3].txt
    );
    var img = $("img"); // استخراج جميع الصور في الصفحة
    var images = $("#checkImageCount").find("img");
    if (images.length > 0) {
      // console.log();
      // test_local_image($(img).attr('src'))
      img.on("load", function () {
        if (
          this.complete &&
          typeof this.naturalWidth !== "undefined" &&
          this.naturalWidth > 0
        ) {
          window.imagesCount = window.imagesCount + 1;
          if (window.imagesCount >= images.length) {
            setTimeout(() => {
              setiImageLoaded(false);
            }, 500);
          }
        }
      });
    } else {
      setiImageLoaded(false);
    }
  };

  const toggleModel = (title, body) => {
    // @ts-ignore
    dispatch(setModalBotton([...buttonsListForModelFinish]));
    // @ts-ignore
    dispatch(setModalTitle(title));
    // @ts-ignore
    dispatch(setModalSubTitle(""));
    // @ts-ignore
    dispatch(setModalBody(""));
    dispatch(setModalBody(body));
    // @ts-ignore
    dispatch(setBlur(!blurExam));
    // @ts-ignore
    dispatch(setModal(!modal));
  };
  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setModal(false));
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[props.lang].BackExam,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary", //tail wind css background
      action: closeModel, //tail wind css background
    },
  ];

  return (
    <div className="w-full pt-4 flex flex-col ">
      <div id="checkImageCount" className="hidden"></div>
      <div className="w-full flex flex-col justify-start gap-4">
        {props.question?.QuesHeadTxt?.length > 0 ? (
          <Title
            bookmark={false}
            quesNumber={props.quesNumber}
            question={props.question}
          />
        ) : (
          <></>
        )}

        <div
          className={`border rounded-lg border-gray-300 p-2 bg-white relative`}
        >
          {fullReport === false && examConfig.marked === true ? (
            <div
              className={`absolute text-sm py-1 -top-[20px] border bg-white ${
                props.lang == "ar"
                  ? "left-3 font-[Droid]"
                  : "right-3 font-[roman]"
              } px-3 rounded-full gap-1 flex flex-row z-10`}
            >
              <span
                className={`${
                  props.lang === "ar"
                    ? "left-5 font-[ArabicEqu]"
                    : "right-5 font-[roman]"
                } flex`}
              >
                {props.lang === "ar"
                  ? ![1, 2].includes(parseInt(props.question.QuesScore))
                    ? props.question.QuesScore
                    : ""
                  : props.question.QuesScore}
              </span>
              <span className="flex font-[roman] rtl:font-[Droid]">
                {props.lang === "ar"
                  ? parseInt(props.question.QuesScore) === 1
                    ? "درجة واحدة"
                    : parseInt(props.question.QuesScore) === 2
                    ? "درجتان"
                    : config.config["ar"].Degrees
                  : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
                      parseInt(props.question.QuesScore)
                    )
                  ? config.config["en"].Degrees
                  : config.config["en"].Degree}
              </span>
            </div>
          ) : (
            ""
          )}
          {imageLoaded == true ? (
            <div className="bg-white absolute w-full h-full p-0 m-0 -ml-2 -mt-2 rounded-xl z-40 opacity-90 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12 animate-spin stroke-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
          <div className="h-2"></div>
          <ResizeImage
            size="rem"
            type="ques"
            text={props.question.QuesTxt}
            style={props.question.cssStyle}
            question={props.question}
          />
          <div className="h-7"></div>
          <AnswerMCQ
            id={props.id}
            answers={props.question.QuesAns}
            quez={props.question}
          />
          {fullReport === true ? (
            <>
              <br />
              <div
                className={`flex flex-row gap-4  ${
                  briefReport === false &&
                  props.question.QuesShowAns1.status === true
                    ? "justify-between"
                    : "justify-end"
                }`}
              >
                {briefReport === false &&
                props.question.QuesShowAns1.status === true ? (
                  <button
                    onClick={() => {
                      toggleModel(
                        examConfig.showAnserButton[props.lang],
                        props.question.QuesShowAns1.txt
                      );
                    }}
                    className="w-fit border-primary bg-white text-[#151048] mb-3 border text-center py-2 px-4 rounded-full"
                  >
                    {examConfig.showAnserButton[props.lang]}
                  </button>
                ) : (
                  ""
                )}
                {examConfig.marked === true ? (
                  <div
                    className={`${
                      props.lang == "ar"
                        ? "left-5 font-[ArabicEqu]"
                        : "right-5 font-[roman]"
                    } w-fit bg-white h-fit mt-1 border-gray-400 text-gray-400 border text-center py-1 px-4 rounded-full flex flex-row-reverse gap-2`}
                  >
                    <div className={`flex`}>{props.question.QuesScore}</div>
                    <div className={`flex`}>
                      {props.lang == "ar" ? "\\" : "/"}
                    </div>
                    <div className={`flex`}>
                      {current_is_answerd &&
                      current_is_answerd &&
                      current_is_answerd.userScore != null
                        ? current_is_answerd.userScore
                        : 0}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
