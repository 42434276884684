import React, {useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setStartExam, setBlur, setActualNumber} from '../store/actions';
import config from "../sql/config.json";
import { useParams } from 'react-router';


export const StartExam = (props) => {
    const params = useParams()
    const dispatch = useDispatch();
    const { examCounter, timer, actualNumber } = useSelector(state => state.userAppReducer);
    const exams = props.exam

    const closeModel = () => {
        localStorage.removeItem('retake_'+params.id)
        // @ts-ignore
        dispatch(setStartExam(false))
        // @ts-ignore
        dispatch(setBlur(false))
        if (props.timer.active) {
            window.timer = true
        }
    }
    useEffect(() => {
        let a =0 ;
        exams.questions.map(question=>{
          if(question.QuesType != "Paragraph"){
            a = a+1
          }else{
            question.list.map(e=>{
              a = a + 1
            })
          }
        })
        dispatch(setActualNumber(a))
    }, [props.active])


    if (props.active === true) {
        return (
            <div className='w-screen h-screen bg-primary bg-opacity-25 fixed top-12 left-0 flex justify-center items-center align-middle content-center self-center z-50 md:max-w-[875px] md:m-auto right-0 overflow-hidden'>
                <div className="bg-white px-4 py-7 rounded-3xl w-11/12 sm:w-10/12  min-h-[30vh] h-fit max-h-[80vh] flex flex-col md:flex-row  gap-4 justify-start md:items-center">
                    <div className="w-full md:w-5/5 flex flex-col">
                        <h1 className="w-full flex justify-center font-[900] text-center text-xl font-['roman'] rtl:font-['Droid']">{props.title}</h1>
                        <div className="h-fit shrink-0 flex justify-center">
                            <img className="text-center w-auto h-56" src="/bg/start_exam_bg.svg" alt='' />
                        </div>
                    </div>
                    <div className="h-full w-full flex flex-col gap-3">
                        {
                            (props.timer.active === true) ? (
                                <div className="flex flex-row justify-between gap-4 py-2 px-5 bg-white rounded-full border border-primary text-[#151048]">
                                    <div className="font-light font-['roman'] rtl:font-['Droid']">{config.config[props.lang].examDuration}</div>
                                    <div className="font-['roman'] rtl:font-['ArabicEqu']">{timer}</div>
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        <div className="flex flex-row justify-between gap-4 py-2 px-5 bg-white rounded-full border border-primary text-[#151048]">
                            <div className="font-light font-['roman'] rtl:font-['Droid']">{config.config[props.lang].quesNum}</div>
                            <div className="font-['roman'] rtl:font-['ArabicEqu']">{actualNumber}</div>
                        </div>
                        <button onClick={() => { closeModel() }} type='button' className="bg-primary w-32 text-center select-none py-2 px-4 rounded-full text-white m-auto font-['roman'] rtl:font-['Droid']">{config.config[props.lang].startExam}</button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<></>)
    }

}