import React, { useState, useEffect } from "react";
import { isTablet, isDesktop } from "react-device-detect";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { setUserAnswer } from "../store/actions";
import { useParams } from "react-router-dom";

export const ResizeImage = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const { currentQues, userAnswer, fullReport, briefReport, examConfig } =
    useSelector((state) => state.userAppReducer);
  console.log(examConfig?.meta_data?.grade);
  useEffect(() => {
    window.imagesCount = 0;
    resizeImage();
  }, [isLandscape]);

  useEffect(() => {
    function handleOrientationChange(mql) {
      setIsLandscape(mql.matches);
    }
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    mediaQueryList.addListener(handleOrientationChange);

    // Clean up the listener function when the component unmounts
    return () => {
      mediaQueryList.removeListener(handleOrientationChange);
    };
  }, []);

  const resizeImage = (text, type, size) => {
    // return text;
    $("#txt").html(text);
    // let MainHeight = 30;
    // let MainWidth = 30;
    // let _Height = 12.43;
    // let _Width = 98.34;
    // var images = $("#txt").find("img");
    // if (isDesktop) {
    //   MainHeight = 40;
    //   MainWidth = 340.19;
    //   for (let index = 0; index < images.length; index++) {
    //     var image_height = parseFloat(
    //       $(images[index]).css("height").replace("px", "")
    //     );
    //     var image_width = parseFloat(
    //       $(images[index]).css("width").replace("px", "")
    //     );
    //     var new_height = (MainHeight / _Height) * image_height * 0.063;
    //     var new_width = (MainWidth / _Width) * image_width * 0.063;
    //     if (type == "showAnswer") {
    //       $(images[index]).css("width", new_width * 1 + size);
    //     } else {
    //       $(images[index]).css("width", new_width * 0.52 + size);
    //     }
    //     let linkInamge = $(images[index]).attr("src");
    //     // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
    //     $(images[index]).attr(
    //       "src",
    //       linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
    //     );
    //     $(images[index]).css("height", "auto");
    //     $(images[index]).css("position", "relative");
    //   }
    // } else if (isTablet) {
    //   MainHeight = 36.5;
    //   MainWidth = 276.89;
    //   for (let index = 0; index < images.length; index++) {
    //     var image_height = parseFloat(
    //       $(images[index]).css("height").replace("px", "")
    //     );
    //     var image_width = parseFloat(
    //       $(images[index]).css("width").replace("px", "")
    //     );
    //     var new_height = (MainHeight / _Height) * image_height * 0.063;
    //     var new_width = (MainWidth / _Width) * image_width * 0.063;
    //     if (isLandscape == true) {
    //       var new_width = new_width * 0.75;
    //     }
    //     $(images[index]).css("width", new_width * 0.63 + size);
    //     let linkInamge = $(images[index]).attr("src");
    //     // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
    //     $(images[index]).attr(
    //       "src",
    //       linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
    //     );

    //     $(images[index]).css("height", "auto");
    //     $(images[index]).css("position", "relative");
    //     $(images[index]).attr("resize", "isTablet");
    //   }
    // } else {
    //   MainHeight = 23;
    //   MainWidth = 181.95;
    //   for (let index = 0; index < images.length; index++) {
    //     var image_height = parseFloat(
    //       $(images[index]).css("height").replace("px", "")
    //     );
    //     var image_width = parseFloat(
    //       $(images[index]).css("width").replace("px", "")
    //     );
    //     var new_width = (MainWidth / _Width) * image_width * 0.063;
    //     if (isLandscape == true) {
    //       var new_width = new_width * 1.1;
    //     }
    //     if (type == "showAnswer") {
    //       $(images[index]).css("width", new_width * 0.97 + size);
    //     } else {
    //       $(images[index]).css("width", new_width * 0.83 + size);
    //     }
    //     let linkInamge = $(images[index]).attr("src");
    //     // $(images[index]).attr('src',(window.location.pathname).split('index.html')[0]+linkInamge)
    //     $(images[index]).attr(
    //       "src",
    //       linkInamge.replace(/http:\/\/192.168.3.108:3000\/uploads/g, "/image")
    //     );
    //     $(images[index]).css("height", "auto");
    //     $(images[index]).css("position", "relative");
    //   }
    // }

    // let cont = $("#txt").find("div");
    // for (let index = 0; index < cont.length; index++) {
    //   if ($(cont[index]).css("text-align") == "left") {
    //     $(cont[index]).attr("dir", "ltr");
    //   }
    // }
    // let cont_2 = $("#txt").find("p");
    // for (let index = 0; index < cont_2.length; index++) {
    //   if ($(cont_2[index]).css("text-align") == "left") {
    //     $(cont_2[index]).attr("dir", "ltr");
    //   }
    // }

    if (!window.blanks) {
      window.blanks = function (myAnswer, rightAnswer, index, i, count) {
        const localAnswersStringify = localStorage.getItem(
          "userAnswer_" + params.id
        );
        const localAnswers =
          localAnswersStringify && localAnswersStringify !== null
            ? JSON.parse(localAnswersStringify)
            : [];
        let a = {
          answer: myAnswer,
          index: index,
          parent: false,
          right: rightAnswer,
          type: "blanks",
          score: props.question.QuesScore,
          userScore:
            parseInt(myAnswer) == parseInt(rightAnswer)
              ? parseInt(props.question.QuesScore)
              : 0,
          totalAns: count,
        };
        if (!localAnswers?.[index]?.[0]) {
          localAnswers[index] = [];
          localAnswers[index][i] = a;
        } else {
          localAnswers[index][i] = a;
        }
        // dispatch(userAnswer)
        localStorage.setItem(
          "userAnswer_" + params.id,
          JSON.stringify(localAnswers)
        );
        return true;
      };
    }
    let index = 0;

    let htmlCode = $("#txt").html();
    if (htmlCode?.includes("##")) {
      // console.log(true);
      const matches = htmlCode.match(/##(.*?)##/g);
      const count = matches ? matches.length : 0;
      let a = htmlCode.replace(/##(.*?)##/g, (match, rightAnswer) => {
        let ind = index++;
        // console.log(match.replace(/##/g,''));
        // let val=JSON.parse(localStorage.getItem(`userAnswer_${params.id}`))?.[0]?.answer||"";
        // console.log(count);
        const userAnsLength = Math.min(
          userAnswer?.[currentQues]?.[ind]?.answer
            ? userAnswer?.[currentQues]?.[ind]?.answer?.length * 10 + 50
            : rightAnswer?.length * 10 + 50,
          400
        );
        const ansLength = Math.min(rightAnswer?.length * 10 + 50, 400);
        console.log("mmmmm", userAnsLength, ansLength);
        const rightIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        class="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-green-600   "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>`;
        const wrongIconBox = `<svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        style="position: absolute;top:-3px; right: -3px; "
        class="
                    w-5 h-5 border -top-2 -right-2 z-10 absolute bg-white rounded-full
                    stroke-red-600   "
      >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
      </svg>`;
        const rightInput = `<span style="width:fit-content; display:inline-block; position: relative"><input disabled value=${rightAnswer} type="text" style="width:${ansLength}px; text-align: center" class="border border-green-600 bg-green-100 h-9 max-w-full mx-1 inline-block  border-dashed rounded-lg py-1 px-1 font-extrabold"/>${rightIconBox}</span>`;

        return `<span style="width:fit-content; display:inline-block; position: relative">
        
        <input ${fullReport && "disabled"} value="${
          userAnswer?.[currentQues]?.[ind]?.answer || ""
        }" style="width:${userAnsLength}px; text-align: center" onkeyup="window.blanks(this.value,'${match.replace(
          /##/g,
          ""
        )}',${currentQues},${ind},${count})" type="text" class="border border-primary ${
          fullReport && userAnswer?.[currentQues]?.[ind]?.userScore
            ? "border-green-600 bg-green-100"
            : fullReport && userAnswer?.[currentQues]?.[ind]?.userScore === 0
            ? "border-red-600 bg-red-100"
            : ""
        } ${
          fullReport ? "font-extrabold" : ""
        } h-9 max-w-full mx-1 inline-block  border-dashed rounded-lg py-1 px-1 " />
        ${
          fullReport && userAnswer?.[currentQues]?.[ind]?.userScore
            ? rightIconBox
            : fullReport && userAnswer?.[currentQues]?.[ind]?.userScore === 0
            ? wrongIconBox
            : ""
        } </span> ${
          fullReport && userAnswer?.[currentQues]?.[ind]?.userScore !== 1
            ? rightInput
            : ""
        }`;
      });
      console.log(a);
      $("#txt").html(a);
    }

    let content = $("#txt").html();
    return content;
  };
  return (
    <div
      className="w-full"
      dir={examConfig?.meta_data?.language == "ar" ? "rtl" : "ltr"}
    >
      <div
        id="txt"
        className={`hidden ${
          examConfig?.meta_data?.grade <= 8 ? "font-teshrin" : "font-times"
        }`}
      ></div>
      <div
        id={`${
          examConfig?.meta_data?.grade <= 8
            ? "quest-font-teshrin"
            : "quest-font-times"
        }`}
        onDragExit={props.lang == "ar" ? "ltr" : "rtl"}
        className={` ${
          props.fonts
            ? props.fonts
            : examConfig?.meta_data?.grade <= 8
            ? "font-teshrin"
            : "font-times"
        } text-xl leading-[40px] sm:leading-[50px] ${examConfig?.meta_data?.language == "ar" ? "text-right":"text-left"} w-full`}
        dangerouslySetInnerHTML={{
          // __html: resizeImage(props.text, props.type, props.size),
          __html: `<style>${props.style}</style>${resizeImage(
            props.text,
            props.type,
            props.size
          )}`,
        }}
      ></div>
    </div>
  );
};
