import React, { useEffect, useState } from "react";
import { ResizeImage } from "./resizeImage";
import { Title } from "./Title";
import config from "../sql/config.json";
import { useSelector, useDispatch } from "react-redux";
import {
  setUserAnswer,
  setBlur,
  setEssayModal,
  setEssayModalBody,
  setEssayModalButton,
  setEssayModalTitle,
  setEssayModalUser,
} from "../store/actions";
import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";

export const Essay = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  // @ts-ignore
  const {
    modal,
    blurExam,
    currentQues,
    userAnswer,
    fullReport,
    briefReport,
    examConfig,
  } = useSelector((state) => state.userAppReducer);
  const [imageLoaded, setiImageLoaded] = useState(true);
  const [loaded, setLoaded] = useState(true);
  const [current_is_answerd, setCurrent_is_answerd] = useState([]);

  useEffect(() => {
    setCurrent_is_answerd(
      userAnswer.filter((e) => e != null && e.index == currentQues)[0]
    );
    window.imagesCount = 0;
    setTimeout(() => {
      setLoaded(false);
    }, 50);
    checkImageCount();
  }, [loaded]);

  const checkImageCount = () => {
    $("#checkImageCount").html(props.question?.QuesTxt);
    var img = $("img"); // استخراج جميع الصور في الصفحة
    var images = $("#checkImageCount").find("img");
    if (images.length > 0) {
      img.on("load", function () {
        if (
          this.complete &&
          typeof this.naturalWidth !== "undefined" &&
          this.naturalWidth > 0
        ) {
          window.imagesCount = window.imagesCount + 1;
          if (window.imagesCount >= images.length) {
            setTimeout(() => {
              setiImageLoaded(false);
            }, 500);
          }
        }
      });
    } else {
      setiImageLoaded(false);
    }
  };
  const myAnswer = (answer, quez, index) => {
    userAnswer[currentQues] = {
      index: index,
      answer: answer,
      right: quez.QuesRightAns,
      type: quez.QuesType,
      parent: false,
      score: parseInt(quez.QuesScore),
      userScore: null,
    };

    localStorage.setItem("userAnswer_" + params.id, JSON.stringify(userAnswer));
    localStorage.setItem("retake_" + params.id, true);

    // @ts-ignore
    dispatch(setUserAnswer(userAnswer));
  };

  const toggleEssayModel = (title, body, user) => {
    // @ts-ignore
    dispatch(setEssayModalButton([...buttonsListForModelFinish]));
    // @ts-ignore
    dispatch(setEssayModalTitle(title));
    // @ts-ignore
    dispatch(setEssayModalBody(body));
    // @ts-ignore
    dispatch(setBlur(!blurExam));
    // @ts-ignore
    dispatch(setEssayModalUser(user));
    // @ts-ignore
    dispatch(setEssayModal(!modal));
  };
  const closeModel = () => {
    // @ts-ignore
    dispatch(setBlur(false));
    // @ts-ignore
    dispatch(setEssayModal(false));
  };

  const buttonsListForModelFinish = [
    {
      text: config.config[props.lang].BackExam,
      color: "text-white", // tail wind css color
      border: "border", //tail wind css border
      borderColor: "border-primary", //tail wind css border
      background: "bg-primary", //tail wind css background
      action: closeModel, //tail wind css background
    },
  ];

  return (
    <div className="w-full pt-4 flex flex-col">
      <div id="checkImageCount" className="hidden"></div>
      <div className="w-full flex flex-col justify-start gap-4">
        {props.question?.QuesHeadTxt?.length > 0 ? (
          <Title
            bookmark={false}
            quesNumber={props.quesNumber}
            question={props.question}
          />
        ) : (
          <></>
        )}
        <div className="border rounded-lg border-gray-300 flex flex-col p-2 gap-4 relative">
          {fullReport === false && examConfig.marked === true ? (
            <div
              className={`absolute text-sm py-1 -top-[19px] border bg-white ${
                props.lang == "ar"
                  ? "left-5 font-[Droid]"
                  : "right-5 font-[roman]"
              } px-5 rounded-full gap-1 flex flex-row z-10`}
            >
              <span
                className={`${
                  props.lang === "ar"
                    ? "left-5 font-[ArabicEqu]"
                    : "right-5 font-[roman]"
                } flex`}
              >
                {props.lang === "ar"
                  ? ![1, 2].includes(parseInt(props.question.QuesScore))
                    ? props.question.QuesScore
                    : ""
                  : props.question.QuesScore}
              </span>
              <span className="flex">
                {props.lang === "ar"
                  ? parseInt(props.question.QuesScore) === 1
                    ? "درجة واحدة"
                    : parseInt(props.question.QuesScore) === 2
                    ? "درجتان"
                    : config.config["ar"].Degrees
                  : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].includes(
                      parseInt(props.question.QuesScore)
                    )
                  ? config.config["en"].Degrees
                  : config.config["en"].Degree}
              </span>
            </div>
          ) : (
            ""
          )}
          {imageLoaded == true ? (
            <div className="bg-white absolute w-full h-full p-0 m-0 -ml-2 -mt-2 rounded-xl z-40 opacity-90 flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-12 h-12 animate-spin stroke-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
            </div>
          ) : (
            ""
          )}
          <ResizeImage
            size="rem"
            type="ques"
            text={props.question?.QuesTxt}
            question={props.question}
          />
          {fullReport === true && briefReport === false ? (
            <div className="flex flex-col gap-4 justify-end">
              <div className="w-full bg-gray-200 text-gray-600 border rounded-lg p-2 border-primary opacity-50 border-opacity-30">
                {current_is_answerd && current_is_answerd.answer
                  ? current_is_answerd.answer
                  : config.config[props.lang].noEssayAnswerUser}
              </div>

              <div
                className={`flex flex-row gap-4 ${
                  briefReport === false &&
                  props.question.QuesShowAns1.status === true
                    ? "justify-between"
                    : "justify-end"
                }`}
              >
                <button
                  onClick={() => {
                    toggleEssayModel(
                      config.config[props.lang].TheModelAnswer,
                      props.question,
                      current_is_answerd && current_is_answerd.answer
                        ? current_is_answerd.answer
                        : ""
                    );
                  }}
                  className="w-fit border-primary bg-white text-[#151048] border text-center py-2 px-4 rounded-full"
                >
                  {config.config[props.lang].ShowModelAnswer}
                </button>
                {examConfig.marked === true ? (
                  <div
                    className={`${
                      props.lang == "ar"
                        ? "left-5 font-[ArabicEqu]"
                        : "right-5 font-[roman]"
                    } w-fit bg-white h-fit mt-1 border-gray-400 text-gray-400 border text-center py-1 px-4 rounded-full flex flex-row-reverse gap-2`}
                  >
                    <div className={`flex`}>{props.question.QuesScore}</div>
                    <div className={`flex`}>
                      {props.lang == "ar" ? "\\" : "/"}
                    </div>
                    <div className={`flex`}>
                      {current_is_answerd &&
                      current_is_answerd &&
                      current_is_answerd.userScore != null
                        ? current_is_answerd.userScore
                        : 0}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <textarea
              onChange={(e) =>
                myAnswer(e.target.value, props.question, currentQues)
              }
              rows={6}
              placeholder={config.config[props.lang].EssayPlaceHolder}
              className="w-full border-primary bg-white text-[#151048] focus:border-primary border border-opacity-40 py-1 px-2  rounded-xl"
              value={userAnswer[currentQues] && userAnswer[currentQues].answer}
            ></textarea>
          )}
        </div>
      </div>
    </div>
  );
};
