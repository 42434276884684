import React, { useState } from 'react';
import { ResizeImage } from "./resizeImage"
import { useSelector, useDispatch } from "react-redux";
import { setUserAnswer } from '../store/actions';
import { useNavigate, useParams } from 'react-router-dom';

export const AnswerMCQ = (props) => {

    const params = useParams()
    const exams = props.exam
    const dispatch = useDispatch();
    // @ts-ignore
    const { currentQues, userAnswer, briefReport, fullReport } = useSelector(state => state.userAppReducer);
    const [currentAnswer, setCurrentAnswer] = useState("");

    const myAnswer = (answer, index,ansId) => {
        setCurrentAnswer(answer)
        userAnswer[index] = ({
            index: index,
            answer: answer,
            right: props.quez.QuesRightAns,
            type: props.quez.QuesType,
            parent: false,
            score: parseInt(props.quez.QuesScore),
            userScore: props.quez.QuesRightAns[0]?.id===ansId?parseInt(props.quez.QuesScore):0
        })
        // @ts-ignore
        dispatch(setUserAnswer(userAnswer))
        localStorage.setItem('userAnswer_' + params.id, JSON.stringify(userAnswer))
        localStorage.setItem('retake_'+params.id, true)

    }

    const AnswerIcon = ({ point, index, roundeed, numeric_type, txt, style,ansId }) => {

        if (userAnswer[currentQues]) {
            if (point === userAnswer[currentQues].answer && props.quez.QuesRightAns[0]?.id===ansId) {
                return (
                    <div key={index} className={`w-full select-none  flex ${(numeric_type == 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`}>
                        <div className={`bg-[#288D57] text-white shrink-0  ${roundeed} relative mt-2 w-7 h-7 flex justify-center items-center  ${(exams?.meta_data?.language == 'ar') ? ('font-[Droid]') : (exams?.meta_data?.language !== 'ar' && [1].includes(numeric_type) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]')} `}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                {/* <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" /> */}
                            </svg>
                        </div>
                        <ResizeImage size="rem" type="answer" text={txt} />
                    </div>
                )
            } else {
                if (point != userAnswer[currentQues].answer && props.quez.QuesRightAns[0]?.id===ansId) {
                    return (
                        <div key={index} className={`w-full select-none  flex ${(numeric_type == 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`}>
                            <div className={`bg-[#288D57] text-white shrink-0  ${roundeed} relative mt-2 w-7 h-7 flex justify-center items-center shrink-0  ${(exams?.meta_data?.language == 'ar') ? ('font-[Droid]') : (exams?.meta_data?.language !== 'ar' && [1].includes(numeric_type) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]')} `}>
                                {point}
                            </div>
                            <ResizeImage size="rem" type="answer" text={txt} />
                        </div>
                    )
                } else if (point == userAnswer[currentQues].answer && props.quez.QuesRightAns[0]?.id!==ansId) {
                    return (
                        <div key={index} className={`w-full select-none  flex ${(numeric_type === 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`}>
                            <div className={`bg-[#AB2620] shrink-0 text-white  ${roundeed} relative mt-2 w-7 h-7 flex justify-center items-center shrink-0  ${(exams?.meta_data?.language == 'ar') ? ('font-[Droid]') : (exams?.meta_data?.language !== 'ar' && [1].includes(numeric_type) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]')} `}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    {/* <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" /> */}
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <ResizeImage size="rem" type="answer" text={txt} />
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className={`w-full select-none  flex ${(numeric_type === 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`}>
                            <div className={` ${(props.quez.QuesRightAns === pointNumericType(index,false)) ? 'bg-[#288D57] shrink-0 text-white' : style.color + ' ' + style.background + ' ' + style.border + ' ' + style.borderColor} ${style.rounded} relative mt-2 w-7 h-7 flex justify-center items-center shrink-0  ${(exams?.meta_data?.language == 'ar') ? ('font-[Droid]') : (exams?.meta_data?.language !== 'ar' && [1].includes(props.quez.numeric_type) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]')} `}>{point}</div>
                            <ResizeImage size="rem" type="answer" text={txt} />
                        </div>
                    )
                }
            }
        } else {
            return (
                <div key={index} className={`w-full select-none  flex ${(numeric_type === 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`}>
                    <div className={` ${(props.quez.QuesRightAns[0]?.id===ansId) ? 'bg-[#288D57] shrink-0 text-white' : style.color + ' ' + style.background + ' ' + style.border + ' ' + style.borderColor} ${style.rounded} relative mt-2 w-7 h-7 flex justify-center items-center shrink-0  ${(exams?.meta_data?.language !== 'ar' && [1].includes(props.quez.numeric_type)) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]'} `}>{point}</div>
                    <ResizeImage size="rem" type="answer" text={txt} />
                </div>
            )
        }
    }

    const pointNumericType = (index, reverse) => {
        let a= [];
        if(reverse === true){
            a = ['ا', 'ب', 'جـ', 'د', 'و']
        }else{
            a = ['a', 'b', 'c', 'd', 'e']
        }
        return a[index]
    }

    return (
        <div className={`flex flex-col justify-start gap-3 font-[roman] rtl:font-[Droid]`}>
            {
                props.answers.map((answer, index) => (
                    (fullReport === true && briefReport == false) ? (
                        <AnswerIcon key={index} index={index} style={answer.buttons} point={answer.buttons.point} txt={answer.text} roundeed={answer.buttons.rounded} numeric_type={(props.quez.numeric_type && props.quez.numeric_type == 1) ? 1 : 0} ansId={answer.id} />
                    ) : (
                        <div key={index} className={`w-full flex select-none ${(props.quez.numeric_type == 1) ? ' justify-end' : ' justify-start'} items-start content-start self-start gap-3`}>
                            {console.log(answer)}
                            <button
                                className={`w-fit select-none  flex ${(props.quez.numeric_type && props.quez.numeric_type == 1) ? 'flex-row-reverse' : 'flex-row'} justify-start items-start content-start self-start gap-3`} 
                                onClick={() => (myAnswer((props.quez.numeric_type == 1)?(pointNumericType(index,true)):(answer.buttons.point), currentQues ,answer.id))}
                            >
                                <div className={`${(index == 0) ? 'rtl:pt-[2px]' : ''} ${(currentAnswer == answer.buttons.point || (userAnswer[currentQues] && userAnswer[currentQues].answer == answer.buttons.point)) ? answer.buttons.activeColor + ' ' + answer.buttons.activeBackground : answer.buttons.color + ' ' + answer.buttons.background} ${answer.buttons.border + ' ' + answer.buttons.borderColor + ' ' + answer.buttons.rounded} relative mt-2 w-7 h-7 shrink-0 justify-center items-center ${(exams?.meta_data?.language == 'ar') ? ('font-[Droid]') : (exams?.meta_data?.language !== 'ar' && [1].includes(props.quez.numeric_type)) ? 'font-[Droid]' : 'font-[roman] rtl:font-[Droid]'} `}>{answer.buttons.point}</div>
                                <ResizeImage size="rem" type="answer" text={answer.text} style={ props.quez.cssStyle} />
                            </button>
                        </div>
                    )
                ))
            }
        </div>
    );


}