import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NavBar, Login, AccessibleScreen } from "../components";
import { lessons as lessonsList, books } from "../data";
import langConfig from "../configuration/lang.json";
// import { useIsAuthenticated } from "react-auth-kit";
import { setBlur, setLang, setUserAnswer } from "../store/actions";
import axios from "axios";

export const Lessons = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  // const isAuthenticated = useIsAuthenticated();
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState("ar");
  const dispatch = useDispatch();
  const { lang, userLogin } = useSelector((state) => state.userAppReducer);
  const [scoring, setScoring] = useState(
    JSON.parse(localStorage.getItem("scoring_" + params.subjectID)) || []
  );
  // const [lessonsLd, setLessonId] = useState(lessonsList[params.subjectID]);
  const [lessonsLd, setLessonId] = useState([]);
  const [accessable, setAccessable] = useState(false);
  const [scoreList, setScoreList] = useState([]);
  const [lessonsList, setLessonsList] = useState([]);

  const [currentDivision, setCurrentDivision] = useState(
    localStorage.getItem("division")
  );
  const [retake, setRetake] = useState([]);
  useEffect(() => {
    dispatch(setBlur(true));
    getLastUserScore();
    checkAccessible();
    getLessonList();
  }, [params.subjectID]);

  const mytoken = localStorage.getItem("token");
  const headers = {
    "access-token": "qwertasdfg123@+1",
    Authorization: `Bearer ${mytoken}`,
    // Authorization  : `Bearer ${mytoken}`
  };

  const getLessonList = async () => {
    await axios
      .get(
        `/data/lessons.json`
      )
      .then(async (respons) => {
        // console.log("respons");
        // console.log(respons.data.exams);
        // console.log("respons ");
        // setLessonId(respons.data[params.subjectID]);
        let lessonsLList=respons.data.exams.filter((el)=>el.subjectId==params.subjectID)
        setLessonsList(lessonsLList);
        setLanguage(respons.data.lang);
        // dispatch(setLang(respons.data.lang))
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getLastUserScore = async () => {
    // dispatch(
    //   setBlur(
    //     isAuthenticated() === false &&
    //       localStorage.getItem("userLogin") &&
    //       localStorage.getItem("userLogin") === false
    //       ? true
    //       : false
    //   )
    // );
    setRetake(Array(lessonsList[params.subjectID]?.length).fill(false));
    getScoreFromServer();
  };

  const getScoreFromServer = async () => {
    var scores = [];
    let items = [];
  };

  const LessonBox = ({ item, count }) => {
    var link = "";
    link = "/exam/" + params.subjectID + "/" + item.id;
    scoring[item.index] = JSON.parse(
      localStorage.getItem("userAnswer_" + item.id) || false
    );
    retake[item.index] = JSON.parse(
      localStorage.getItem("retake_" + item.id) || false
    );
    setScoring(scoring);
    setRetake(retake);
    return (
      <div
        key={item.id}
        className={`${count>0?"bg-white":"bg-gray-200"} min-h-14 h-fit border shadow-md rounded-lg flex flex-row gap-3 justify-start items-center overflow-hidden`}
      >
        <button
          disabled={count<=0}
          onClick={() =>
            goToFunction(
              item,
              link,
              JSON.parse(
                localStorage.getItem("userAnswer_" + item.id) ? true : false
              )
            )
          }
          className="w-full flex h-full items-center"
        >
          {
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`
                            felx mx-2 w-6 h-6 shrink-0
                            ${
                              scoring[item.index]
                                ? "stroke-white fill-primary"
                                : "fill-white stroke-primary"
                            }
                        `}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
              {scoring[item.index] ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              ) : (
                ""
              )}
            </svg>
          }
          <h1 className="w-full flex text-start p-2 text-[#151048] font-[Almarai]">
            {item?.name}
          </h1>

          {retake[item.index] === true ? (
            <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
              <div className="w-4/12 bg-primary rounded-full"></div>
            </div>
          ) : scoring[item.index] ? (
            <div className="relative flex flex-row justify-end mx-2 w-32 border rounded-full h-3 bg-gray-200">
              <div className="w-full bg-primary rounded-full"></div>
            </div>
          ) : (
            ""
          )}
        </button>
      </div>
    );
  };
  const goToFunction = (item, link, score) => {
    // const ae = require("../data/exams/" + item.id + ".json");
    // if (score) {
    //   ae.userAnswer =
    //     JSON.parse(localStorage.getItem("userAnswer_" + item.id)) || [];
    // }
    // if (localStorage.getItem("retake_" + item.id) == "true") {
    navigate(link);
    // } else {
    //   navigate(!score ? link : link + "/full-report");
    // }
  };

  const checkAccessible = () => {};
  return (
    <div
      dir={language === "ar" ? "rtl" : "ltr"}
      className="flex flex-col justify-start h-full w-full p-0 my-0 mx-auto md:max-w-[875px] md:m-auto  overflow-hidden pb-20"
    >
      {/* <Login current={true} active={activeLogin} /> */}
      <NavBar
        menu={false}
        qr={false}
        titleText={books.filter((book) => book.id == params.subjectID)[0]?.name}
        bookmarkIcon={false}
        title={true}
        id={params.subjectID}
        back={true}
        language={language}
        to={"/subjects/" + currentDivision}
      />
      {loading ? (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 m-auto mt-32 flex animate-spin stroke-primary"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </>
      ) : (
        <>
          <div className={`flex flex-col gap-3 w-full h-fit px-4 py-3`}>
            {lessonsList.map((e, index) =>
              e.question_count ? (
                <LessonBox
                  key={index}
                  item={{ index: index, ...e }}
                  count={e.question_count}
                />
              ) : (
                <LessonBox
                  key={index}
                  item={{ index: index, ...e }}
                  count={e.question_count}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};
