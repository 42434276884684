import React, { useEffect, useState } from 'react';
import { useSignIn, useIsAuthenticated } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import langConfig from "../configuration/lang.json"
import config from "../sql/config.json"
import { PrivacyPolicy, TermsAndConditions } from '../pages';
import { books, governorates, schools, divisions } from '../data';
import AWS from 'aws-sdk';
import sha1 from 'crypto-js/sha1';
import $ from 'jquery'
import { setModal, setModalBody, setModalBotton, setModalSubTitle, setModalTitle, setUserLogin, setBlur } from '../store/actions';
import { v4 as uuidv4 } from "uuid";

export const Login = (props) => {
    AWS.config.update({
        accessKeyId: "AKIA452FOHXXJI3KVTFY",
        secretAccessKey: "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",
        region: "eu-west-1",
    });
    var documentClient = new AWS.DynamoDB.DocumentClient();
    const signIn = useSignIn();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { lang, modal, modalBody, modalTitle, modalSubTitle, modalBotton } = useSelector(state => state.userAppReducer);
    const isAuthenticated = useIsAuthenticated()
    const [startAnimation, setStartAnimation] = useState(false)
    const [login, setLogin] = useState(false)
    const [startAs, setStartAs] = useState(false)
    const [im, setIm] = useState("")
    const [email, setEmail] = useState('');
    const [rightEmail, setRightEmail] = useState(true);
    const [subject, setSubject] = useState(null)
    const [showModel, setShowModel] = useState(false)
    const [subjectList, setSubjectList] = useState(books)
    const [governorate, setGovernorate] = useState(null)
    const [showModelGov, setShowModelGov] = useState(false)
    const [govList, setGovList] = useState(governorates)
    const [recaptchaImage, setRecaptchaImage] = useState('')
    const [recaptchaCode, setRecaptchaCode] = useState(null)
    const [recaptchaUserCode, setRecaptchaUserCode] = useState('')
    const [recaptchaUserError, setRecaptchaUserError] = useState(false)
    const [canSendOTP, setCanSendOTP] = useState(false)
    const [sending, setSending] = useState(false)
    const [OTP, setOTP] = useState("");
    const [mainOTP, setMainOTP] = useState("");
    const [readyToOTP, setReadyToOTP] = useState(false)
    const [otpDone, setOtpDone] = useState(false)
    const [errorMessage, setErrorMessage] = useState([false, ''])
    const [proceedButton, setProceedButton] = useState([true, false, false])
    const [updateVerified, setUpdateVerified] = useState(false)
    const [timer, setTimer] = useState(59)
    const timeMain = 59;
    const [timerTxt, setTimerTxt] = useState("00:59")
    const [startTime, setStartTime] = useState(false)
    const [schoolListActive, setSchoolListActive] = useState(false)
    const [privacy, setPrivacy] = useState(false)
    const [tems, setTems] = useState(false)

    const [mandatory, setMandatory] = useState({
        student: [{
            email: {
                field: null,
                mandatory: true
            },
            governorate: {
                field: null,
                mandatory: true
            },
            division: {
                field: null,
                mandatory: true
            },
            schoolLanguage: {
                field: null,
                mandatory: true
            },
        }],
        teacher: [{
            email: {
                field: null,
                mandatory: true
            },
            name: {
                field: null,
                mandatory: true
            },
            phone: {
                field: null,
                mandatory: false
            },
            subject: {
                field: null,
                mandatory: true
            },
            governorate: {
                field: null,
                mandatory: true
            },
        }]
    })
    const [userName, setUserName] = useState(null)
    const [schoolName, setSchoolName] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [optionsList, setOptionsList] = useState([])
    const [options, setOptions] = useState([])
    const [divisionsListActive, setDivisionsListActive] = useState(false)
    const [loading, setLoading] = useState(false);
    const [divisionsList, setDivisionsList] = useState(divisions)
    const [schoolList, setSchoolList] = useState(schools)
    const [confirm, setConfirm] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [agreement, setAgreement] = useState(false)

    useEffect(() => {
        setMainOTP(generateRandomNumber())
        setTimeout(() => {
            setStartAnimation(true)
        }, 300);
        setTimeout(() => {
            if (isAuthenticated()) {
                if(props.to){
                    navigate(props.to)
                }
            } else {
                setLogin(true)
                recaptchaReload()
                // navigate('/login')
            }
        }, 4000);
    }, [])

    const showSubjectList = () => {
        setShowModel(!showModel)
    }
    const selectSubject = (item) => {
        setSubject(item)
        setShowModel(false)
    }
    const showGovernorateList = () => {
        setShowModelGov(!showModelGov)
    }
    const selectGov = (gov) => {
        setGovernorate(gov)
        setShowModelGov(false)
    }
    const recaptchaReload = () => {
        const code = generateRandomString(6);
        setRecaptchaCode(code)
        setRecaptchaImage(exportTextToImage(code, 'font-[Almarai]', 'recaptchaImage'))
    }
    const generateRandomString = (length) => {
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let randomString = '';
        for (let i = 0; i < length; i++) {
            const randomNum = Math.floor(Math.random() * chars.length);
            randomString += chars[randomNum];
        }
        return randomString;
    }
    const generateRandomArray = () => {
        const arr = new Array(5);
        for (let i = 0; i < arr.length; i++) {
            arr[i] = Math.random() < 0.5;
        }
        return arr;
    }
    const blurRecaptcha = generateRandomArray();
    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const exportTextToImage = (text, font, filename) => {
        var canvas = document.createElement('canvas');
        canvas.width = 500;
        canvas.height = 200;
        var ctx = canvas.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.fillRect(10, 15, canvas.width, canvas.height);
        const canvasX = [0.7, 2, 2.8, 3.7, 4.6, 5.4]
        const canvasY = shuffleArray([3, 2, 3, 2, 2, 1.6])

        drawLetter(ctx, text[0], '700 150px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[0], canvas.height / canvasY[0], blurRecaptcha[0])
        drawLetter(ctx, text[1], '700 120px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[1], canvas.height / canvasY[1], blurRecaptcha[1])
        drawLetter(ctx, text[2], '700 150px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[2], canvas.height / canvasY[2], blurRecaptcha[2])
        drawLetter(ctx, text[3], '700 100px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[3], canvas.height / canvasY[3], blurRecaptcha[3])
        drawLetter(ctx, text[4], '700 120px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[4], canvas.height / canvasY[4], blurRecaptcha[4])
        drawLetter(ctx, text[5], '700 105px Noto Sans', '#151048', 'center', 'middle', (canvas.width / 6) * canvasX[5], canvas.height / canvasY[5], blurRecaptcha[5])
        // return canvas;
        drawLine(ctx, [0, 30], [500, 80], '#151048', 15, 1);
        // drawLine(ctx, [0, 100], [500, 100], '#151048', 15, 2);
        drawLine(ctx, [0, 120], [500, 170], '#151048', 15, 3);
        const dataURL = canvas.toDataURL('image/png');
        return dataURL;
    }
    const drawLetter = (ctx, letter, font, color, align, baseline, width, height, blurRecaptcha) => {
        ctx.font = font;
        ctx.fillStyle = color;
        ctx.textAlign = align;
        ctx.textBaseline = baseline;
        if (blurRecaptcha == true) {
            ctx.filter = 'blur(5px)';
        } else {
            ctx.filter = 'blur(0px)';
        }
        ctx.fillText(letter, width, height);
    }
    const drawLine = (ctx, begin, end, stroke, width, index) => {
        if (stroke) {
            ctx.strokeStyle = stroke;
        }
        if (width) {
            ctx.lineWidth = width;
        }
        ctx.filter = (blurRecaptcha[index] == true ? 'blur(4px)' : 'blur(0px)')
        ctx.beginPath();
        ctx.moveTo(...begin);
        ctx.lineTo(...end);
        ctx.stroke();
    }
    const recaptchaCheck = (code) => {
        if (code.value.length <= 6) {
            setRecaptchaUserCode(code.value)
        }
        if ((code.value).toLowerCase() !== recaptchaCode.toLowerCase()) {
            setRecaptchaUserError(true)
            setCanSendOTP(false)
        } else {
            setRecaptchaUserError(false)
            setCanSendOTP(true)
        }
    }
    const generateRandomNumber = () => {
        const chars = '0123456789';
        let randomString = '';
        for (let i = 0; i < 6; i++) {
            const randomNum = Math.floor(Math.random() * chars.length);
            randomString += chars[randomNum];
        }
        return randomString;
    }
    const sendOTP = async (email) => {
        setErrorMessage([false, ''])
        setSending(true)
        try {
                await createUser()

        } catch (error) {
            setErrorMessage([true,error])
        }
        setSending(false)
    }
    const createUser = async () => {
        const shaEmail = sha1(email).toString();
        localStorage.setItem("mp", shaEmail)
    }
    const addUser = async () => {
        const timestampUTC = Date.now();
        const dateUTC = new Date(timestampUTC);
        const optionsTime = { timeZone: 'Africa/Cairo' };
        const timestampEgypt = dateUTC.toLocaleString('en-US', optionsTime);

        const objectData = (im === "teacher") ? {
            name: userName,
            email: email,
            phone: phoneNumber,
            schoolLanguages: options[0].param,
            division: options[1].param,
            school: schoolName,
            teacher: true
        } : {
            email: email,
            schoolLanguages: options[0].param,
            division: options[1].param,
            teacher: false
        }
        var user_aws = {
            TableName: "exams",
            Item:{
                id: uuidv4(),
                time: timestampEgypt,
                verified: false,
                agreement: agreement,
                governorate: governorate,
                scores: [],
                ...objectData,
            }
        };

        documentClient.scan(
            {
                TableName: "exams",
                FilterExpression: 'email = :email',
                ExpressionAttributeValues: {
                    ':email': email
                }
            },
            (err, data) => {
                if (err) {
                    console.log(err);
                } else {
                    if(data.Count <= 0){
                        documentClient.put(user_aws,
                            function (err, ee) {
                              if (err) {
                                setErrorMessage([true,err])
                                console.log(err);
                              } else {
                                localStorage.setItem('uuid',JSON.stringify({login:true,...user_aws.Item}))
                                loginFunction({login:true,...user_aws.Item})
                              }
                            }
                        );
                        console.log('done');
                    }else{
                        console.log("no added");
                        localStorage.setItem('uuid',JSON.stringify(data.Items[0]))
                        loginFunction({login:true,...user_aws.Item})
                    }
                }
            }
        );
        setSending(false)
    }
    const CheckUser = () => {
        var counter = false
        documentClient.scan(
            {
                TableName: "exams",
                FilterExpression: 'email = :email',
                ExpressionAttributeValues: {
                    ':email': email
                }
            },
            (err, data) => {
                if (err) {
                    console.log(err);
                    counter = false
                } else {
                    if(data.Count <= 0){
                        counter = false
                        console.log(data);
                    }else{
                        console.log(data);
                        localStorage.setItem('uuid',JSON.stringify(data.Items[0]))
                        counter = true
                    }
                }
            }
        );
        return counter;
    };


    const nextFunction = async () => {
    }

    const loginFunction = async (user) => {
        // const user = JSON.parse(localStorage.getItem('_auth_state'))
        try {
            await signIn({
                token: user.id,
                expiresIn: 10200 * 100,
                tokenType: "Bearer",
                authState: { as: im, ...user },
                refreshToken: user.id,
                refreshTokenExpireIn: 10200 * 100,
            })
            dispatch(setUserLogin(true))
            localStorage.setItem('userLogin',true)
            localStorage.setItem('division',`${user.schoolLanguages}/${user.division}`)
            navigate('/home')
        } catch (error) {
            setErrorMessage([true,error])
            console.log(error);
            // window.location.reload()
        }
    }
    const addEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        // const a = proceedButton
        if (emailRegex.test(email)) {
            setRightEmail(true)
            // a[0] = true
        } else {
            setRightEmail(false)
            // a[0] = false
        }
        // setProceedButton(a)
        setEmail(email)
    }
    const addName = (name) => {
        let data = mandatory
        data["teacher"][0].name.field = (name.length > 0) ? name : false
        setMandatory(data)
        setUserName((name.length > 3) ? name : false)
    }
    const addSchoolName = (name) => {
        let data = mandatory
        data["teacher"][0].name.field = (name.length > 0) ? name : false
        setMandatory(data)
        setSchoolName((name.length > 4) ? name : false)
    }
    const addPhone = (phone) => {
        const phoneRegex = /^[0-9]+$/;
        if (phoneRegex.test(phone)) {
            if (phone.length === 0) {
                setPhoneNumber(null)
            } else {
                setPhoneNumber(phone.substring(0, 11))
            }
        }
    }
    const optionModaleFunction = (txt, list) => {
        list[0].title = txt;
        setOptionsList(list)
        if (txt === 'schoolList') {
            setSchoolListActive(!schoolListActive)
        } else if (txt === 'divisionsList') {
            setDivisionsListActive(!divisionsListActive)
        }
    }
    const optionSelected = (props) => {
        const opt = options
        opt[props.type] = props
        setOptions(opt)
        setSchoolListActive(false)
        setDivisionsListActive(false)
    }
    const buttonsListForModelFinish = [
        {
            text: config.config['ar'].yes,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-primary', //tail wind css border
            background: 'bg-primary', //tail wind css background
            action: () => loginNote_2_Modal(), //tail wind css background
        },
        {
            text: config.config['ar'].no,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-primary', //tail wind css border
            background: 'bg-primary', //tail wind css background
            action: () => closeModelNote(), //tail wind css background
        }
    ];
    const buttonsListForModelFinish2 = [
        {
            text: langConfig['ar'].Continue,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-primary', //tail wind css border
            background: 'bg-primary', //tail wind css background
            action: () => loginFunction(email, sha1(email).toString())
        },
        {
            text: langConfig['ar'].Exit,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-primary', //tail wind css border
            background: 'bg-primary', //tail wind css background
            action: () => closeModelNote(), //tail wind css background
        }
    ];
    const loginNote_2_Modal = () => {
        dispatch(setModal(false))
        dispatch(setModalTitle(''))
        dispatch(setModalBody(''))
        dispatch(setModalSubTitle(langConfig['ar'].loginNote_2))
        dispatch(setModalBotton(buttonsListForModelFinish2))
        dispatch(setModal(true))
    }
    const closeModelNote = () => {
        // localStorage.clear()
        dispatch(setModal(false))
        navigate("/")
    }
    const confirmFunction = (item) => {
        setIm(item)
        setConfirm(true)
    }

    if (props.active === true) {
        return (
            <div dir="rtl" className={`fixed w-screen h-screen top-0 left-0 flex justify-center items-center`}>
                <div className={`flex w-full h-full bg-slate-700 bg-opacity-40`}></div>
                <div className={`absolute z-50 w-[95vw] md:w-[500px] h-fit max-h-[80vh] ${(startAs) ? 'p-5' : 'p-10'} bg-white overflow-y-scroll rounded-lg flex flex-col justify-start gap-5`}>
                    {
                        (!startAs) ? (
                            <div className={`flex flex-col gap-2`}>
                                <img className="text-center w-full h-auto md:w-auto md:h-[30vh] px-3" src="/bg/startAs.svg" alt='' />
                                {/* <div className={`text-lg text-center text-[#151048] font-[Almarai]`}>{langConfig['ar'].startAsNote}</div> */}
                                <div className={`text-2xl text-center text-[#151048] font-extrabold font-[Almarai] mt-4`}>{langConfig['ar'].startAs}</div>
                            </div>
                        ) : ''
                    }
                    {
                        (readyToOTP === false && isConfirm === false) ? (
                            <div className={`w-full h-fit shrink-0 grid grid-cols-2 justify-center items-center gap-3`}>
                                <button
                                    onClick={() =>confirmFunction("student")}
                                    className={`w-full flex rounded-full order-1 p-2 ${(im === "student") ? 'bg-[#151047] border-0 text-white' : 'border-2 bg-white text-[#151047]'} border-[#151047] justify-center font-[Almarai]`}
                                >
                                    {langConfig['ar'].Student}
                                </button>
                                <button
                                    onClick={() =>confirmFunction("teacher")}
                                    className={`w-full flex rounded-full order-2 p-2 ${(im === "teacher") ? 'bg-[#151047] border-0 text-white' : 'border-2 bg-white text-[#151047]'} border-[#151047] justify-center font-[Almarai]`}
                                >
                                    {langConfig['ar'].Teacher}
                                </button>
                                {
                                    (confirm === true)?$(
                                        <button
                                            onClick={() =>{
                                                setIsConfirm(true)
                                                setStartAs(true)
                                            }}
                                            className={`w-full col-span-2 mt-4 order-3 flex rounded-full p-2 bg-[#151047] border-0 text-white border-[#151047] justify-center font-[Almarai]`}
                                        >
                                            {langConfig['ar'].Confirm}
                                        </button>
                                    ):''
                                }
                            </div>
                        ):''
                    }
                    {
                        (readyToOTP === false) ? (
                            (startAs) ? (
                                (im === 'teacher' && isConfirm === true) ? (
                                    <div dir={`rtl`}
                                        className={`w-full h-fit blur-none flex-col gap-10 justify-start`}
                                    >
                                        <button className="" onClick={()=>{
                                            setIm(null)
                                            setConfirm(false)
                                            setIsConfirm(false)
                                            setStartAs(false)
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-[#151047]">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                            </svg>
                                        </button>
                                        {/* start name */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].yourName} <span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <input
                                                    onChange={(e) => addName(e.target.value)}
                                                    className={`${(mandatory["teacher"][0].name.mandatory === true && userName === false) ? 'border-red-600' : ''} p-2 border rounded-lg font-[Almarai] font-normal`}
                                                    placeholder={langConfig['ar'].yourName}
                                                ></input>
                                                {
                                                    (userName === false) ? (

                                                        <p className={`font-[Almarai] font-normal text-red-600 px-2`}>
                                                            {
                                                                langConfig['ar'].nameLength
                                                            }
                                                        </p>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end name */}



                                        {/* start email */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].EnterEmailTitle} <span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <input dir="ltr" onChange={(e) => addEmail(e.target.value)} pattern='\w{3,16}' className={`${(rightEmail === false) ? 'border-red-600' : ''}  p-2 border rounded-lg font-[Almarai] font-normal`} placeholder='name@domain.com'></input>
                                                <p className={`font-[Almarai] font-normal ${(rightEmail === false) ? 'text-red-600' : 'text-gray-300'} px-2`}>
                                                    {
                                                        (rightEmail === false) ? langConfig['ar'].validEmailAllert : langConfig['ar'].EnterEmailMessage
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        {/* end email */}



                                        {/* start phone */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].EnterPhone} <span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-row-reverse gap-0">
                                                <div className='w-[100px] flex flex-row-reverse justify-between items-center p-2 border rounded-l-lg font-[Almarai] font-normal'>
                                                    <img className='w-auto h-5 flex' src='/bg/eg_icon.png' alt="" />
                                                    <span className="text-gray-500 font-[Almarai]">+2</span>
                                                </div>
                                                <input dir='ltr' type='number' pattern='[0-9]' onChange={(e) => { addPhone(e.target.value) }} value={phoneNumber} className={`${(rightEmail === false) ? 'border-red-600' : ''} flex w-full p-2 border rounded-r-lg font-[Almarai] font-normal`} placeholder='01012345678'></input>
                                            </div>
                                        </div>
                                        {/* end phone */}


                                        {/* start School languages */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].schoolLang}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('schoolList', schoolList)} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(schoolListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[0]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[0]) ? langConfig['ar'].schoolLang : options[0].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (schoolListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (schoolListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                schoolList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai] text-[#151048]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end languages */}

                                        {/* start School divisions */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].selectDivision}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('divisionsList', divisionsList)} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(divisionsListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[1]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[1]) ? langConfig['ar'].yourDivision : options[1].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (divisionsListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (divisionsListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                divisionsList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end divisions */}


                                        {/* start governorate */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].Governorate}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => showGovernorateList()} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(showModelGov === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    {
                                                        (governorate === null) ? (
                                                            <b className="font-[Almarai] font-normal text-gray-300">{langConfig['ar'].selectGovernorate}</b>
                                                        ) : (
                                                            <b className="font-[Almarai] font-normal">{governorate['ar']}</b>
                                                        )
                                                    }
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (showModelGov === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (showModelGov === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                govList.map((item, index) => <button key={index} onClick={() => selectGov(item)} className="flex w-full py-1 px-2 font-[Almarai]">{item['ar']}</button>)
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end governorate */}



                                        {/* start school name */}
                                        <div className={`w-full h-fit flex-col mt-4 gap-2 justify-start`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].SchoolName}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <input onChange={(e) => { addSchoolName(e.target.value) }} className={`${(schoolName === false) ? 'border-red-600' : ''} p-2 border rounded-lg font-[Almarai] font-normal`} placeholder={`مدرسة النيل`}></input>
                                            </div>
                                        </div>
                                        {/* end School name */}


                                        {/* start recaptcha */}
                                        <div className="flex w-full flex-row gap-3 mt-4 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <img className='w-24 border rounded-xl h-auto' src={recaptchaImage} alt='' />
                                                {
                                                    (canSendOTP === false) ? (
                                                        <button className="flex w-fit bg-white p-2 shrink-0 border rounded-xl" onClick={() => recaptchaReload()}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                            </svg>
                                                        </button>
                                                    ) : ''
                                                }
                                            </div>
                                            <input
                                                dir="ltr"
                                                onChange={(e) => recaptchaCheck(e.target)}
                                                value={recaptchaUserCode}
                                                className={`flex w-full p-2 border rounded-xl font-[Almarai] font-normal ${(recaptchaUserError === true) ? 'border-red-500' : ''}`}
                                            ></input>
                                        </div>
                                        {/* end recaptcha */}

                                        {/* start agreement */}
                                        <div className="flex w-full flex-row gap-3 mt-4 mb-6 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <input id="agreement" type='checkbox' checked={agreement} onClick={()=>setAgreement(!agreement)}  class="w-6 h-6 text-[#151048] bg-primary ring-offset-primary ring-primary border-gray-300 rounded " />
                                            </div>
                                            <label for="agreement" className="font-[Almarai]">
                                                {langConfig['ar'].agreement}
                                            </label>
                                        </div>
                                        {/* end agreement */}
                                        {/* start agreement */}
                                        <div className="w-full leading-7 text-sm gap-2 mt-4 mb-6 justify-start items-center font-[Almarai]">
                                                {langConfig['ar'].agreementNote[0]}
                                                <button type="button" onClick={()=>{setTems(true)}} className="font-[Almarai] text-blue-500 w-fit underline px-2">{langConfig['ar'].agreementNote[1]}</button>
                                                {langConfig['ar'].agreementNote[2]}
                                                <button type="button" onClick={()=>{setPrivacy(true)}} className="font-[Almarai] text-blue-500  w-fit underline px-2">{langConfig['ar'].agreementNote[3]}</button>
                                                {langConfig['ar'].agreementNote[4]}
                                        </div>
                                        {/* end agreement */}
                                        {(errorMessage[0] === true)?(
                                            <div className="w-full text-center text-red-400 leading-7 text-sm gap-2 mt-4 mb-6 justify-center items-center font-[Almarai]">
                                                {errorMessage[1]}
                                            </div>
                                        ):''}

                                        {/* start proceed */}
                                        <div className="flex w-full flex-row gap-3 mt-4 justify-start items-center">
                                            <button
                                                className={`
                                                    ${((sending === false)) ? 'w-64 ' : 'w-fit'}
                                                    ${(
                                                        canSendOTP === true &&
                                                        agreement === true &&
                                                        options[0] !== 'undefined' && 
                                                        options[1] !== 'undefined'  && 
                                                        email !== "" && 
                                                        userName !== null && 
                                                        phoneNumber !== null && 
                                                        governorate !== null && 
                                                        schoolName !== null
                                                    ) ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500'}
                                                    rounded-full p-3 font-[Almarai] capitalize font-bold m-auto
                                                `}
                                                onClick={() =>
                                                    (email !== "" && sending === false && agreement === true && userName !== null && phoneNumber !== null && options[0] !== 'undefined' && options[1] !== 'undefined' && governorate !== null && schoolName !== null) ?
                                                    setModalConfirm(true)
                                                        : {}
                                                }
                                            >
                                                {(sending === false) ? langConfig['ar'].proceed : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white animate-spin">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                )}
                                            </button>

                                        </div>
                                        {/* end proceed */}
                                    </div>
                                ) : (
                                    <div dir={`rtl`}
                                        className={`w-full h-fit blur-none flex-col gap-10 justify-end`}
                                    >

                                        <button className="" onClick={()=>{
                                            setIm(null)
                                            setConfirm(false)
                                            setIsConfirm(false)
                                            setStartAs(false)
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-[#151047]">
                                                {
                                                    (lang == "ar")?(
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                                    ):(
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                                    )
                                                }
                                            </svg>
                                        </button>
                                        {/* start email */}
                                        <div className={`w-full h-fit blur-none flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg blur-none text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].EnterEmailTitle} <span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full blur-none flex-col gap-1">
                                                <input dir="ltr" onChange={(e) => addEmail(e.target.value)} pattern='\w{3,16}' className={`${(rightEmail === false) ? 'border-red-600' : ''}  p-2 border rounded-lg font-[Almarai] font-normal`} placeholder='name@domain.com'></input>
                                                <p className={`blur-none font-[Almarai] font-normal ${(rightEmail === false) ? 'text-red-600' : 'text-gray-300'} px-2`}>
                                                    {
                                                        (rightEmail === false) ? langConfig['ar'].validEmailAllert : langConfig['ar'].EnterEmailMessage
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        {/* end email */}



                                        {/* start School languages */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].schoolLang}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('schoolList', schoolList)} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(schoolListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[0]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[0]) ? langConfig['ar'].schoolLang : options[0].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (schoolListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (schoolListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                schoolList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai] text-[#151048]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end languages */}

                                        {/* start School divisions */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].selectDivision}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => optionModaleFunction('divisionsList', divisionsList)} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(divisionsListActive === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    <b className={` ${(!options[1]) ? 'text-gray-300' : ''} font-[Almarai] font-normal`}>{(!options[1]) ? langConfig['ar'].yourDivision : options[1].name['ar']}</b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (divisionsListActive === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (divisionsListActive === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                divisionsList.map((e, index) =>
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => optionSelected(e)}
                                                                        className="flex w-full py-1 px-2 font-[Almarai]"
                                                                    >{e.name['ar']}</button>
                                                                )
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end divisions */}


                                        {/* start governorate */}
                                        <div className={`w-full h-fit flex-col gap-2 justify-start mt-4`}>
                                            <h3 className="text-lg text-[#151048] font-[Almarai] font-bold">
                                                {langConfig['ar'].Governorate}<span className="text-red-500">*</span>
                                            </h3>
                                            <div className="flex w-full flex-col gap-1">
                                                <button onClick={() => showGovernorateList()} className={`
                                                    flex flex-row justify-between p-2 border bg-white
                                                    ${(showModelGov === true)?'rounded-t-xl':'rounded-xl'}
                                                `}>
                                                    {
                                                        (governorate === null) ? (
                                                            <b className="font-[Almarai] font-normal text-gray-300">{langConfig['ar'].selectGovernorate}</b>
                                                        ) : (
                                                            <b className="font-[Almarai] font-normal">{governorate['ar']}</b>
                                                        )
                                                    }
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        {
                                                            (showModelGov === true) ? (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                                            ) : (
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            )
                                                        }
                                                    </svg>

                                                </button>
                                                {
                                                    (showModelGov === true) ? (
                                                        <div className={`flex flex-col gap-2 justify-start p-2 border rounded-b-xl divide-y divide-white h-fit max-h-[30vh] overflow-auto -mt-1 bg-gray-300 bg-opacity-60`}>
                                                            {
                                                                govList.map((item,index) => <button key={index} onClick={() => selectGov(item)} className="flex w-full py-1 px-2 font-[Almarai]">{item['ar']}</button>)
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        </div>
                                        {/* end governorate */}


                                        {/* start recaptcha */}
                                        <div className="flex w-full flex-row gap-3 mt-4 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <img className='w-24 border rounded-xl h-auto' src={recaptchaImage} alt='' />
                                                {
                                                    (canSendOTP === false) ? (
                                                        <button className="flex w-fit bg-white p-2 shrink-0 border rounded-xl" onClick={() => recaptchaReload()}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                            </svg>
                                                        </button>
                                                    ) : ''
                                                }
                                            </div>
                                            <input
                                                dir='ltr'
                                                onChange={(e) => recaptchaCheck(e.target)}
                                                value={recaptchaUserCode}
                                                className={`flex w-full p-2 border rounded-xl font-[Almarai] font-normal`}
                                            ></input>
                                        </div>
                                        {/* end recaptcha */}

                                        {/* start agreement */}
                                        <div className="flex w-full flex-row gap-3 mt-4 mb-6 justify-start items-center">
                                            <div className="flex flex-row gap-1 w-fit shrink-0">
                                                <input id="agreement" type='checkbox' checked={agreement} onClick={()=>setAgreement(!agreement)}  class="w-6 h-6 text-[#151048] bg-primary ring-offset-primary ring-primary border-gray-300 rounded " />
                                            </div>
                                            <label for="agreement" className="font-[Almarai]">
                                                {langConfig['ar'].agreement}
                                            </label>
                                        </div>
                                        {/* end agreement */}
                                        {/* start agreement */}
                                        <div className="w-full leading-7 text-sm gap-2 mt-4 mb-6 justify-start items-center font-[Almarai]">
                                                {langConfig['ar'].agreementNote[0]}
                                                <button type="button" onClick={()=>{setTems(true)}} className="font-[Almarai] text-blue-500 w-fit underline px-2">{langConfig['ar'].agreementNote[1]}</button>
                                                {langConfig['ar'].agreementNote[2]}
                                                <button type="button" onClick={()=>{setPrivacy(true)}} className="font-[Almarai] text-blue-500  w-fit underline px-2">{langConfig['ar'].agreementNote[3]}</button>
                                                {langConfig['ar'].agreementNote[4]}
                                        </div>
                                                {(errorMessage[0] === true)?(
                                                <div className="w-full text-center text-red-400 leading-7 text-sm gap-2 mt-4 mb-6 justify-center items-center font-[Almarai]">
                                                    {errorMessage[1]}
                                                </div>
                                                ):''}
                                        {/* end agreement */}

                                        {/* start proceed */}
                                        <div className="flex w-full flex-row gap-3 mt-4 justify-start items-center">
                                            <button
                                                className={`
                                                    ${((sending === false)) ? 'w-64 ' : 'w-fit'}
                                                    ${(canSendOTP === true && agreement ===true && email !== "" && options[0] !== 'undefined' && options[1] !== 'undefined' && governorate !== null) ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500'}
                                                    rounded-full p-3 font-[Almarai] capitalize font-bold m-auto
                                                `}
                                                onClick={() =>
                                                    (canSendOTP === true && agreement ===true && email !== "" && options[0] !== 'undefined' && options[1] !== 'undefined' && governorate !== null) ?
                                                        setModalConfirm(true)
                                                        : {}
                                                }
                                            >
                                                {(sending === false) ? langConfig['ar'].proceed : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white animate-spin">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                    </svg>
                                                )}
                                            </button>

                                        </div>
                                        {/* end proceed */}
                                    </div>
                                )
                            ) : ''
                        ) : (
                            <div className="w-full shrink h-max flex flex-col justify-start mt-18 mb-18 gap-14 overflow-hidden">
                                <div className="text-center font-bold m-auto text-2xl text-[#151048] font-[Almarai]">{langConfig['ar'].EnterYourOTP}</div>
                                {
                                    (otpDone === false) ? (
                                        (timerTxt !== "00:00") ? (
                                            <button className="flex text-center m-auto">{timerTxt}</button>
                                        ) : (
                                            <button onClick={() => sendOTP(email)} className="text-center border-0 bg-none flex flex-row justify-center gap-2">
                                                <div className='font-[Almarai]'>{langConfig['ar'].resendOTP}</div>
                                            </button>
                                        )
                                    ) : ''
                                }
                                {/* <OTPInput
                                    value={OTP}
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    onChange={(e) => checkIfOTPVerified(e)}
                                    inputClassName={`col-span-1 border-b-2 h-[50px] content-center self-center w-full font-[Almarai]  text-[#151048] text-5xl`}
                                    className={`w-[full] flex flex-row-reverse justify-center items-center`}
                                    shouldAutoFocus={true}
                                    inputStyles={{
                                        height: '50px',
                                        width: '25px',
                                    }}
                                /> */}
                                {
                                    (otpDone === true) ? (
                                        <button
                                            onClick={() => nextFunction()}
                                            className={`bg-primary text-white border-0 font-[Almarai] rounded-full text-md p-3 my-4 w-64 m-auto ${(updateVerified === true)?'w-fit':''}`}
                                        >
                                            {(updateVerified === false) ? langConfig['ar'].Next : (
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white animate-spin">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>
                                            )}
                                        </button>
                                    ) : ''
                                }
                            </div>
                        )
                    }
                </div>
                {/* <Modal active={modal} lang={lang} font="font-[Almarai]" /> */}
                {
                    (modal == true)?(
                        <div className="w-screen h-screen bg-[#001670] bg-opacity-25 left-0 flex justify-center items-center align-middle content-center self-center z-50  md:max-w-[650px] right-0 fixed top-0 md:m-auto overflow-hidden">
                            <div id="checkImageCount" className='hidden'></div>
                            <div className="bg-white max-h-[95vh] overflow-hidden rounded-3xl w-11/12 max-w-[500px] h-fit flex flex-col  gap-4 justify-start">
                                <div className='w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start'>
                                    <h1 className={`w-full shrink-0 flex justify-center font-semibold text-center text-xl ${(props.fonts)?props.fonts:''}`}>{modalTitle}</h1>
                                    <div className='block w-full text-center'>
                                        {modalSubTitle}
                                    </div>
                                    <div className='flex h-full text-left rtl:text-right'>
                                        {modalBody}
                                    </div>
                                    <div className="shrink-0 h-10 flex flex-row justify-between gap-5">
                                        {
                                            modalBotton.map((button, index) => (
                                                <button key={index} type='button' onClick={button.action} className={`${button.color + ` ` + button.border + ` ` + button.borderColor + ` ` + button.background} select-none text-center py-2 ${(modalBotton.length <= 1) ? 'w-1/2' : 'w-full'} rounded-full m-auto`}>{button.text}</button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):''
                }
                {
                    (modalConfirm === true)?(
                        <div className="w-screen h-full bg-[#001670] bg-opacity-25 fixed top-12 left-0 flex justify-center items-center align-middle content-center self-center z-50">
                            <div id="checkImageCount" className='hidden'></div>
                            <div className="bg-white max-h-[95vh] overflow-hidden rounded-3xl w-11/12 max-w-[500px] h-fit flex flex-col  gap-4 justify-start">
                                <div className='w-full h-full overflow-y-scroll overflow-hidden px-2 py-4 flex flex-col  gap-4 justify-start'>
                                    <h1 className="w-full shrink-0 flex justify-center font-semibold text-center text-xl font-[Almarai]">{langConfig['ar'].alert}</h1>
                                    <div className='block w-full text-center font-[Almarai]'>
                                        {langConfig['ar'].confirmCriteria}
                                    </div>
                                    <div className="shrink-0 h-10 flex flex-row justify-between gap-5">
                                        <button onClick={()=>{
                                            setModalConfirm(false)
                                            setSending(true)
                                            addUser()
                                        }} className={`border  font-[Almarai] border-primary text-white bg-primary select-none text-center py-2 w-full rounded-full m-auto`}>{langConfig['ar'].Next}</button>
                                        <button onClick={()=>setModalConfirm(false)} className={`border border-primary font-[Almarai] text-white bg-primary select-none text-center py-2 w-full rounded-full m-auto`}>{langConfig['ar'].Back}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ):''
                }
                {
                    (tems || privacy )?$(
                        <div className="absolute top-0 left-0 h-screen w-screen z-50 bg-white overflow-auto">
                        {
                            (tems === true)?$(
                                <TermsAndConditions  nav={false} close={()=>setTems(false)} />
                            ):''
                        }
                        {
                            (privacy === true)?$(
                                <PrivacyPolicy  nav={false} close={()=>setPrivacy(false)} />
                            ):''
                        }
                        </div>
                    ):''
                }
            </div>
        )
    } else {
        return '';
    }
}