import langConfig from "../configuration/lang.json"

export const divisions = [
    {
        id: 1,
        name: {
            ar: langConfig['ar'].literary,
            en: langConfig['en'].literary
        },
        param: "literary",
        type: 1,
    },
    {
        id: 2,
        name: {
            ar: langConfig['ar'].mathematics,
            en: langConfig['en'].mathematics,
        },
        param: "mathematics",
        type: 1
    },
    {
        id: 3,
        name: {
            ar: langConfig['ar'].science,
            en: langConfig['en'].science,
        },
        param: "science",
        type: 1
    },
]