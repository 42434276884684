import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showScreenModal } from "../../store/actions";
import axios from "axios";

const ModalScreen = ({ MyImg }) => {
  const { screenModalValue } = useSelector((state) => state.userAppReducer);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const sendScreen = () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .post(
        "https://emmobank.com/api/add_issue",
        {
          question_id: 156,
          image: MyImg,
        },
        {
          headers: {
            "access-token": "qwertasdfg123@+1",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        
        setLoading(false);
        // console.log("send screen");
        dispatch(showScreenModal(false));
      })
      .catch((error) => {
        setError("error |: we cant send SCreen shot");
      });
  };

  return (
    <>
      {screenModalValue ? (
        <>
          <div className="z-[60] h-screen w-screen bg-gray-700 bg-opacity-45  absolute top-0 left-0 flex flex-col justify-center items-center">
            <div className="w-[80%]  bg-white h-fit p-4 rounded-xl flex flex-col gap-3">
              <div className="flex flex-row justify-between w-full pt-6">
                <div className="  w-full text-center   font-bold ">
                  Screen shoot is done
                </div>
              </div>

              <div className="flex flex-col items-center  pt-2 justify-between w-full gap-4">
                <div>
                  <img className="h-auto w-40" src={MyImg} alt="صورة" />
                </div>
                <div className="bg-red-300 p-2 rounded-lg">
                  {" "}
                  {error && error}
                </div>
                <div className="flex flex-row w-full gap-7">
                  <div
                    className="rounded-full py-1 text-white w-full text-center font-[Almarai] font-bold bg-blue-950"
                    onClick={() => dispatch(showScreenModal(false))}
                  >
                    Display Modal
                  </div>
                  <div
                    className="rounded-full py-1 text-primary w-full text-center font-[Almarai] font-bold bg-gray-400"
                    onClick={sendScreen}
                  >
                    {loading ? "loading...." : "ok send"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalScreen;
