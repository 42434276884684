import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { NavBar, Login } from '../components';
import { useIsAuthenticated } from 'react-auth-kit'
import { setBlur, setBookmark } from '../store/actions';

export const BookMark = (props) => {
    const params = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = useIsAuthenticated()
    const { lang, bookmark, userLogin, blur } = useSelector(state => state.userAppReducer);
    const [data, setData] = useState([]);
    const [activeLogin, setActiveLogin] = useState((isAuthenticated() === false && localStorage.getItem("userLogin") && localStorage.getItem("userLogin") === false) ? true : false);

    useEffect(() => {
        dispatch(setBlur((isAuthenticated() === false && localStorage.getItem("userLogin") && localStorage.getItem("userLogin") === false) ? true : false))
        localStorage.removeItem('comeFrom')
        let a = bookmark.filter(e => e.subject === params.subjectID)
        a.map(item=>
            (item.bookmark.reduce((acc, val) => val === true ? acc + 1 : acc, 0))<=0?a.splice(item,1):true
            )
        setData(a)
        console.log(a);
    }, [])
    const routeToBookMark = (item) => {
        localStorage.setItem('comeFrom', `/bookmark/${item.subject}`)
        let listReport = JSON.parse(localStorage.getItem("report"));
        let currentReport = listReport.filter(e=>(e.breadcrumbs)?e.breadcrumbs.lession == item.lesson:[])
        if(currentReport[0] && currentReport[0].breadcrumbs){
            navigate(`/exam/${params.subjectID}/${item.lesson}/${(parseInt(item.bookmark.indexOf(true))+1)}/full-report`)
        }else{
            navigate(`/exam/${params.subjectID}/${item.lesson}/${(parseInt(item.bookmark.indexOf(true))+1)}`)
        }
    }
    const LessonBox = ({ item }) => {
        let count = item.bookmark.reduce((acc, val) => val === true ? acc + 1 : acc, 0);

        return (
            <div key={item.name} className="bg-white h-14 border shadow-md rounded-lg flex flex-row gap-3 justify-start items-center">
                <button onClick={() => routeToBookMark(item)} className="w-full flex px-2 h-full items-center">
                    <h1 className="w-full flex py-2 text-[#151048] font-[Almarai]">{item.name}</h1>
                    <span className="px-2 w-12 font-[Almarai] rtl:font-[Droid] h-full shrink-0 leading-10  text-center text-[#151048] bg-white relative">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 absolute top-0 bottom-0 m-auto fill-primary stroke-primary z-0">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0z" />
                        </svg>
                        <span className={`absolute top-1 m-auto w-fit ${(count >= 10) ? 'left-5 rtl:right-5' : ''} text-white z-10 font-[Almarai]`}>
                            {count}
                        </span>
                    </span>
                </button>
            </div>
        )
    }
    const checkCount = (item,index) => {
        delete data[index]
        if(index !== -1){
            let obg = bookmark.splice(item,1)
            dispatch(setBookmark(obg))
            localStorage.setItem("bookmark", JSON.stringify(bookmark))
            if(localStorage.getItem("bookmark").length <=0){
                localStorage.removeItem("bookmark")
            }
            setData(data)
        }
        console.log(bookmark);
    }
    return (
        <div dir={(lang === "ar") ? 'rtl' : 'ltr'} className="flex flex-col min-h-screen justify-start content-stretch h-full w-full p-0 m-0 md:max-w-[875px] md:m-auto overflow-hidden">
            <NavBar menu={false} qr={false} bookmark={false} bookmarkTitle={true} back={true} to={'/lessons/' + params.subjectID} />
            <Login current={true} active={activeLogin} />
            <div className={`${blur === true ? 'blur-sm' : ''}`}>
                {
                    (data.length <= 0) ? (
                        <div className="flex w-full m-auto sm:w-8/12 mt-4 flex-col justify-start p-3 gap-4">
                            <h1 className="text-[#151048] text-3xl font-bold text-[Almarai] text-center">{
                                            (lang === "ar")?'كيف تحفظ علامة مرجعية لسؤال؟':'How to bookmark a question?'
                            }</h1>
                            <div className="flex flex-col sm:flex-row sm:rtl:flex-row-reverse w-full gap-6 relative justify-start items-center">
                                <div className="text-[Almarai] flex w-full flex-col">
                                    <div className="text-xl">
                                        {
                                            (lang === "ar")?'يمكنك حفظ علامة مرجعية بالضغط هنا.':'You can bookmark a question by pressing/tapping this icon.'
                                        }
                                    </div>
                                </div>
                                <div className="text-[Almarai] flex w-full relative">
                                    <img src="/bg/Bookmark_1.svg" className="w-96" alt="" />
                                </div>
                            </div>
                            <div className="h-10">
                            </div>
                            <div className="flex flex-col sm:flex-row-reverse sm:rtl:flex-row w-full gap-6 relative justify-start items-end">
                                <div className="text-[Almarai] flex w-full flex-col">
                                    <div className="text-xl">
                                        {
                                            (lang === "ar")?' يمكنك الرجوع إليها من هنا':'Then you can return to it from here.'
                                        }
                                    </div>
                                </div>
                                <div className="text-[Almarai] flex w-full relative">
                                    <img src="/bg/Bookmark_2.svg" className="w-96" alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-3 w-full h-full px-2 py-3">
                            {
                                data.map((item, index) => (item.bookmark.reduce((acc, val) => val === true ? acc + 1 : acc, 0)>0)?<LessonBox key={index} item={item} />:checkCount(item,index))
                            }
                        </div>
                    )
                }
                <div className='h-10'></div>
            </div>
        </div>
    )

}
