import React from 'react';
import { NavBar } from '../components';
import langConfig from "../configuration/lang.json"
import conf from "../../package.json"

export const PrivacyPolicy = ({ nav, close }) => {

    return (
        <div dir={'rtl'} className="flex  font-[Almarai] flex-col justify-start h-full w-full p-0 m-0 md:max-w-[875px] md:m-auto">
            {
                (nav === false) ? (
                    <div>
                        <div
                            className={`flex w-full sticky top-0 px-4 py-3 bg-primary flex-col justify-between  gap-3 rounded-b-lg z-50 font-[Almarai]`}
                        >
                            <div className="flex flex-row-reverse justify-between w-full">
                                <div className="flex w-fit">
                                </div>
                                <div
                                    className={`flex w-full justify-center text-white font-[Almarai] font-bold md:text-xl relative`}
                                >
                                    {langConfig['ar'].PrivacyPolicy}
                                </div>
                                    <div className={`flex w-fit flex-row gap-3`}>
                                        <button
                                            onClick={() => close()}
                                            className="capitalize flex flex-row gap-2 text-white"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className={`w-6 h-6 rotate-180`}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <NavBar switchLang={false} menu={true} qr={false} back={false} title={true} titleText={
                        langConfig['ar'].PrivacyPolicy
                    } />
                )
            }
            <div className="flex flex-col gap-3 w-full h-fit px-4 py-3 font-[Almarai] overflow-auto">
                <div className="indent-3 mt-3">نؤمن في شركة GPS للطبع والنشر والتوزيع بضرورة الحفاظ على أمان بيانات زوار موقعنا الإلكتروني وكيفية استخدام معلوماتهم الشخصية، حيث تخضع الاستفادة من خدمات موقعنا الإلكتروني لبنود سياسة الخصوصية الواردة في هذه الصفحة.  </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">سياسة الخصوصية</div>
                <div className="indent-3">توضح بنود سياسة الخصوصية كيفية استخدام موقع GPS الإلكتروني  للبيانات وكيفية جمعها وخاصة البيانات الشخصية للراغبين في الحصول على خدماتنا.</div>
                <div className="indent-3">نعتبر بيانات الزائرين سرية للغاية ونستخدمها فقط بهدف تحسين خدماتنا لنحافظ على مستوى خدمة مميزة كما يستحقها عملاؤنا وتنص بنود سياسة الخصوصية على عدم استخدام بياناتكم في أي غرض مخالف للغرض السابق ذكره. </div>
                <div className="indent-3">تخضع عمليات جمع بياناتكم واستخدامها ومشاركتها للبنود المتعارف عليها لسياسات الخصوصية في دور النشر والطبع والتوزيع. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">واجباتنا تجاهك</div>
                <div className="indent-3">نلتزم في موقع GPS الإلكتروني بالحفاظ على حقوق المستخدمين وفقاً للقوانين المبرمة في النظام الأوروبي العام لحماية البيانات كما تخضع كذلك لمجموعة من المبادئ تتمثل في: </div>
                <div className="flex w-full flex-row gap-3 justify-start">
                    <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                    <div>يلتزم موقع GPS التزامًا كاملًا بخلق بيئة آمنة عبر الإنترنت لا تتعرض فيها بياناتك الشخصية لعمليات السطو والقرصنة على الإنترنت.</div>
                </div>
                <div className="flex w-full flex-row gap-3 justify-start">
                    <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                    <div>لا يحق لنا بيع بياناتك الشخصية لأي طرف آخر. </div>
                </div>
                <div className="flex w-full flex-row gap-3 justify-start">
                    <div className="shrink-0 w-2 h-2 rounded-full bg-primary mt-3"></div>
                    <div>تستطيع بسهولة الوصول إلى معلوماتك على الموقع والتحكم فيها وتحديثها أو حذفها.  </div>
                </div>
                <div className="indent-3">وبشكل عام يمكنك تصفح موقعنا دون تقديم بيانات شخصية وستظل هويتك مجهولة طيلة مدة زيارتك ولن يتم الكشف عنها إلا إذا قمت بإنشاء حساب ووافقت على إدخال بياناتك، وفي هذه الحالة سيتم استخدام بياناتك وفقًا للأغراض القانونية الموضحة في بنود سياسة الخصوصية. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">حالات مشاركة البيانات </div>
                <div className="indent-3">لا نشارك أي معلومات شخصية مع أي أطراف أخرى ليست لها علاقة مباشرة بتقديم خدمات GPS إلا إذا ألزمنا القانون بذلك وبناءً على ذلك يجوز للشركة الكشف عن المعلومات الشخصية إذا رأت بحسن نية أن الوصول إلى هذه المعلومات أو استخدامها أو الاحتفاظ بها أو الكشف عنها ضروري إلى حد معقول لتحقيق ما يلي: (أ) الالتزام بأي قانون أو لائحة أو إجراء قانوني سارٍ أو أي طلب حكومي واجب النفاذ، أو (ب) إنفاذ شروط الخدمة السارية، ويشمل ذلك التحقيق في الانتهاكات المحتملة لتلك الشروط، أو (ج) الكشف عن أي حالات غش أو مشكلات أمنية أو فنية أو منعها أو معالجتها بأي صورة أخرى أو (د) حماية حقوق أو ممتلكات أو سلامة GPS أو المستفيدين من خدماتنا أو الجمهور العام من أي ضرر وشيك وفقًا لما يقتضيه القانون أو يسمح به.</div>

                {/* section */}
                <div className="text-xl font-bold mt-4">الأمان</div>
                <div className="indent-3">نعتمد في موقع GPS الإلكتروني على تقنيات أمان عالية لنمنع أي وصول غير مصرح به لمعلوماتك، فحينما نقوم بجمع معلومات عنك فإننا نخزنها على قاعدة بيانات في خادم إلكتروني آمن لأن أمانك هو هدفنا الأساسي.</div>

                {/* section */}
                <div className="text-xl font-bold mt-4">حقك حذف بياناتك</div>
                <div className="indent-3">لديك الحق في طلب حذف بياناتك في أي وقت وعندها يقوم موقعنا بحذف كل البيانات والمعلومات الشخصية التي يحتفظ بها عنك بموجب سياسة الاحتفاظ بالبيانات.  </div>
                <div className="indent-3">أما عن الإحصائيات التي نستخدمها لتحسين خدماتنا فإنها تخضع لتجهيل بياناتك عند طلبك لحذفها من على موقعنا مع استخدامها لتحسين تجربة أداء المستخدم وتطويرها بشكل دائم أو في العمليات التسويقية في حال وافقت على تلقي رسائل تسويقية حول خدماتنا ومنتجاتنا.  </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">عمليات التسويق</div>
                <div className="indent-3">نتيح لك إمكانية الاشتراك في خدماتنا التسويقية، حيث  يمكنك تلقي الرسائل التسويقية حول خدماتنا ومنتجاتنا في أي وقت. وبشكل عام يمكنك إدارة تفضيلاتك للتواصل معك ومنها تستطيع إلغاء الاشتراك في تلقي الرسائل التسويقية عن طريق القنوات المختلفة سواء بريد إلكتروني أو رسائل SMS أو غيرها من قنوات التواصل. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">عمليات الشراكات</div>
                <div className="indent-3">في حال شارك موقع GPS في أي عملية اندماج أو استحواذ أو أي شكل من أشكال البيع لبعض أو كل أصولها فإن معلوماتك الشخصية تنتقل تلقائيًا إلى المشتري ويكون الأساس القانوني لعملية النقل هو مصالح الشركة المشروعة ونخطرك بعملية النقل في الوقت المناسب مع وجود اختيار طلب حذف بياناتك في أي وقت. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">حقوق الملكية</div>
                <div className="indent-3">كل الحقوق محفوظة لـ GPS وبالتالي كل تصميمات الموقع وخطوطه ورسوماته واختيارات تلك الأشياء وتنظيمها خاضعة لحقوق الملكية. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">العلامات التجارية</div>
                <div className="indent-3">تعتبر GPS علامة تجارية تخص شركة جي بي اس المحدودة أو شركاتها التابعة وقد تكون مسجلة في أماكن معينة من العالم. </div>

                {/* section */}
                <div className="text-xl font-bold mt-4">تغيير سياسات الخصوصية</div>
                <div className="indent-3">تستطيع GPS تحديث سياسة الخصوصية في أي وقت ولذلك نوصيك بمراجعة سياسة الخصوصية من وقت إلى آخر للتعرف على التغييرات.</div>

                {/* section */}
                <div className="text-xl font-bold mt-4">الموافقة والاستفسارات</div>
                <div className="indent-3">إن حصولك على المعلومات المتوفرة على هذا الموقع يعني الموافقة التلقائية على الشروط والتنويهات المنصوص عليها أعلاه، فإذا كنت لا توافق على أي من هذه الشروط فعليك ألا تستخدم الموقع أو التطبيق الإلكتروني الخاص بنا لأنه باستخدامك له فإنك توافق عليها. وإذا كان لديك تساؤل أو قلق تجاه سياسة الخصوصية هذه أو الطريقة التي نتعامل بها مع البيانات الشخصية من فضلك تواصل مع خدمة العملاء الخاصة بنا.</div>

                <a href={`mailto:exams@gpseducation.com?subject=(GPS Education Exams - v${conf.APP_VERSION} - ${localStorage.getItem('uuid')})`} className="flex w-full text-blue-600 underline text-center justify-center items-center mt-10">للدعم والمساعدة</a>


            </div>
            <div className="h-10"></div>
        </div>
    )

}
