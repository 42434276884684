export const governorates = [
    {
      id: "1",
      ar: "القاهرة",
      en: "Cairo"
    },
    {
      id: "2",
      ar: "الجيزة",
      en: "Giza"
    },
    {
      id: "3",
      ar: "الأسكندرية",
      en: "Alexandria"
    },
    {
      id: "4",
      ar: "الدقهلية",
      en: "Dakahlia"
    },
    {
      id: "5",
      ar: "البحر الأحمر",
      en: "Red Sea"
    },
    {
      id: "6",
      ar: "البحيرة",
      en: "Beheira"
    },
    {
      id: "7",
      ar: "الفيوم",
      en: "Fayoum"
    },
    {
      id: "8",
      ar: "الغربية",
      en: "Gharbiya"
    },
    {
      id: "9",
      ar: "الإسماعلية",
      en: "Ismailia"
    },
    {
      id: "10",
      ar: "المنوفية",
      en: "Menofia"
    },
    {
      id: "11",
      ar: "المنيا",
      en: "Minya"
    },
    {
      id: "12",
      ar: "القليوبية",
      en: "Qaliubiya"
    },
    {
      id: "13",
      ar: "الوادي الجديد",
      en: "New Valley"
    },
    {
      id: "14",
      ar: "السويس",
      en: "Suez"
    },
    {
      id: "15",
      ar: "اسوان",
      en: "Aswan"
    },
    {
      id: "16",
      ar: "اسيوط",
      en: "Assiut"
    },
    {
      id: "17",
      ar: "بني سويف",
      en: "Beni Suef"
    },
    {
      id: "18",
      ar: "بورسعيد",
      en: "Port Said"
    },
    {
      id: "19",
      ar: "دمياط",
      en: "Damietta"
    },
    {
      id: "20",
      ar: "الشرقية",
      en: "Sharkia"
    },
    {
      id: "21",
      ar: "جنوب سيناء",
      en: "South Sinai"
    },
    {
      id: "22",
      ar: "كفر الشيخ",
      en: "Kafr Al sheikh"
    },
    {
      id: "23",
      ar: "مطروح",
      en: "Matrouh"
    },
    {
      id: "24",
      ar: "الأقصر",
      en: "Luxor"
    },
    {
      id: "25",
      ar: "قنا",
      en: "Qena"
    },
    {
      id: "26",
      ar: "شمال سيناء",
      en: "North Sinai"
    },
    {
      id: "27",
      ar: "سوهاج",
      en: "Sohag"
    }
  ]