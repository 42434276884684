import React from "react";
import Canvas from "./components/Canvas";
import Screen from "./components/Screen";
export default function CanvasScreen({ children ,language }) {
  const childRef = React.useRef(null);

  const handleCallback = (messageFromChild) => {
    // handleCanvasCallback();
  };

  const callChildMethod = () => {
    // Access the child component instance via ref and call its method
    childRef.current.childMethod();
  };

  return (
    <div className={`z-20 w-full h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      <Screen>
        <Canvas callback={handleCallback} language={language}>{children}</Canvas>
      </Screen>
    </div>
  );
}
