import React, { useEffect, useRef, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import { useDispatch, useSelector } from "react-redux";
import ModalScreen from "./ModalScreen";
import { showScreenModal } from "../../store/actions";

const Screen = ({ children, callback }) => {
  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [MyImg, setMyImg] = useState("");

  const { screenValue } = useSelector((state) => state.userAppReducer);
  const { screenModalValue } = useSelector((state) => state.userAppReducer);
  const dispatch = useDispatch();

  const captureScreen = async () => {
    try {
      await takeScreenshot(ref.current, {
        width: window.innerWidth,
        height: window.innerHeight,
      });
      const imageData = image.split(",")[1];
      setMyImg("data:image/jpeg;base64," + imageData);
      dispatch(showScreenModal(!screenModalValue));
    } catch (error) {
      console.error("Error capturing or sending screenshot:", error);
    }
  };

  useEffect(() => {

    captureScreen();
    
  }, [screenValue]);

  return (
    <div
      className={`w-full justify-center h-screen p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}
    >
      <div ref={ref}>
        {children}
        <ModalScreen MyImg={MyImg} />
      </div>
    </div>
  );
};

export default Screen;
