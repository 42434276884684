import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ResizeImage } from "./resizeImage"
import langConfig from "../configuration/lang.json"
import { useSelector, useDispatch } from "react-redux";
import config from "../sql/config.json";
import { setUserAnswer, setBriefReport } from '../store/actions';
import { Title } from './Title';

export const EssayEvaluation = (props) => {
  const params = useParams()
  const dispatch = useDispatch();
  const { lang, userAnswer, essayModalBody, examConfig } = useSelector(state => state.userAppReducer);
  const { active } = props;
  const examList = props.exam?.questions;
  const [essayList, setEssayList] = useState([])
  const [currentEssayQues, setCurrentEssayQues] = useState(0);
  const [score, setScore] = useState(0)
  const [modal, setModal] = useState(false)
  const [activeScreen, setActiveScreen] = useState(true)
  const [start, setStart] = useState(false)
  const [activeNext, setActiveNext] = useState(false);
  const [essayIsAnswerd, setEssayIsAnswerd] =useState([])
  const [currentEssayScore, setCurrentEssayScore] = useState([])
  console.log(examList)
  useEffect(() => {
    // console.log(userAnswer);
    getAllEssay()
    setTimeout(() => {
      setStart(true)
    }, 500);
  }, []);

  const getAllEssay = () => {
    let listParagraph = examList?.filter(e => e.QuesType == "Paragraph");
    let items = []
    listParagraph?.map(
      item => {
        let a = item.list?.filter((e, key) => e.QuesType == "essay")
        a.map((e, ind) => {
          e.parent = true
          e.parent_text = item.QuesTxt
          e.index = item.list.findIndex(obj => obj.id == e.id)
          e.key = examList.findIndex(obj => obj.id == item.id)
        })
        items.push(...a)
      }
    )
    let a = examList?.filter((e, key) => e.QuesType == "essay")
    a?.map((e) => {
      e.parent = false
      e.key = examList.findIndex(obj => obj.id == e.id)
      items.push(e)
    })
    setEssayList(items);
    if(items.length && items.length-1){
      setEssayIsAnswerd(new Array(items?.length-1).fill(null))
    }
  }
  const aplayScore = (number, ques) => {
    // return number;
    let e = ( parseInt(number) > parseInt(essayList[currentEssayQues].QuesScore)) ? essayList[currentEssayQues].QuesScore : parseInt(number)
    e = (e<=0)?"0":e;
    setScore(e)
    currentEssayScore[currentEssayQues] = e;
    setCurrentEssayScore(currentEssayScore)
    if(e === parseInt(essayList[currentEssayQues].QuesScore) ){
      essayIsAnswerd[currentEssayQues] = true;
      setEssayIsAnswerd(essayIsAnswerd);
    }else{
      essayIsAnswerd[currentEssayQues] = false;
      setEssayIsAnswerd(essayIsAnswerd);
    }
    // // @ts-ignore
    if (essayList[currentEssayQues].parent === true) {
      if (!userAnswer[essayList[currentEssayQues].key]) {
        userAnswer[essayList[currentEssayQues].key] = []
        userAnswer[essayList[currentEssayQues].key][essayList[currentEssayQues].index] = []
      }
      userAnswer[essayList[currentEssayQues].key][essayList[currentEssayQues].index] = [{
        index: essayList[currentEssayQues].index,
        answer: (userAnswer[essayList[currentEssayQues].key] && userAnswer[essayList[currentEssayQues].key][essayList[currentEssayQues].index] && userAnswer[essayList[currentEssayQues].key][essayList[currentEssayQues].index].answer) ? userAnswer[essayList[currentEssayQues].key][essayList[currentEssayQues].index].answer : config.config[props.lang].noEssayAnswerUser,
        right: number > (essayList[currentEssayQues].QuesScore / 2),
        type: "essay",
        userScore: parseInt(e) || 0,
        score: parseInt(essayList[currentEssayQues].QuesScore),
        status: true
      }]
    } else {
      if (!userAnswer[essayList[currentEssayQues].key]) {
        userAnswer[essayList[currentEssayQues].key] = []
      }
      userAnswer[essayList[currentEssayQues].key] = ({
        index: essayList[currentEssayQues].key,
        answer: (userAnswer[essayList[currentEssayQues].key] && userAnswer[essayList[currentEssayQues].key].answer) ? userAnswer[essayList[currentEssayQues].key].answer : config.config[props.lang].noEssayAnswerUser||null,
        right: number > (essayList[currentEssayQues].QuesScore / 2),
        type: "essay",
        userScore: parseInt(e) || 0,
        score: parseInt(essayList[currentEssayQues].QuesScore),
        status: true
      })
    }
    // @ts-ignore
    localStorage.setItem('userAnswer_' + params.id, JSON.stringify(userAnswer))
    dispatch(setUserAnswer(userAnswer))
    setActiveNext(true)
    // console.log(userAnswer);
  }

  const showQuestion = () => {
    setModal(true)
  }
  const hideQuestion = () => {
    setModal(false)
  }

  const backQues = () => {
    setCurrentEssayQues((currentEssayQues >= 0) ? (currentEssayQues - 1) : 0)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    setScore('')
  }
  const nextQues = () => {
    // console.log(currentEssayScore,currentEssayScore[currentEssayQues] !=='undefined');
    if(activeNext === true || currentEssayScore[currentEssayQues] !=='undefined'){
      setCurrentEssayQues((currentEssayQues == (essayList.length - 1)) ? (essayList.length - 1) : currentEssayQues + 1)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      setScore('')
      if(currentEssayScore[currentEssayQues+1] ==='undefined'){
        setActiveNext(false)
      }
    }
  }

  return (
    <>
      {
        (active === true && activeScreen === true)
          ? (
            <div className='w-screen h-full overflow-x-hidden justify-start overflow-y-auto bg-white fixed top-0 left-0 flex right-0 flex-col z-50 md:max-w-[875px] md:m-auto overflow-hidden'>
              <div className={`shrink-0 flex w-full sticky top-0 px-4 py-3 bg-primary flex-col justify-between  gap-3 rounded-b-lg z-50 font-[Almarai]`}>
                <div className="flex flex-row-reverse justify-between w-full text-white font-[Almarai] font-extrabold">
                  {langConfig[lang].EssaysEvaluation}
                </div>
              </div>
              {(start === true) ? (<div className="w-full h-fit flex flex-col justify-between gap-5 py-4 px-3">
                <div className="w-full h-full flex flex-col justify-start gap-5">
                  <div className="flex w-full flex-row justify-between">
                    <div className="text-[20px] text-[#707070]">{config.config[props.lang].TheModelAnswer}</div>
                    <button onClick={() => showQuestion()} className="shrink-0 w-fit bg-black bg-opacity-60 p-1 px-2 rounded-full flex flex-row justify-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>
                      <div className="text-white text-sm ">{config.config[props.lang].showQues}</div>
                    </button>
                  </div>
                  <div className="text-[20px] text-[#707070] border rounded-xl p-2">
                    <ResizeImage size="rem" type="modelAnswer" text={essayList[currentEssayQues] ? essayList[currentEssayQues].QuesShowAns1.txt : ''} />
                  </div>
                  <div className="text-[20px] text-[#707070]">{config.config[props.lang].YourAnswer}</div>
                  <div className="text-[20px] text-[#707070] border rounded-xl p-2">
                    <ResizeImage size="rem" type="modelAnswer" text={(userAnswer[essayList[currentEssayQues]?.key] && userAnswer[essayList[currentEssayQues]?.key].answer) ? userAnswer[essayList[currentEssayQues]?.key].answer : config.config[props.lang].noEssayAnswerUser} />
                  </div>
                  {
                    (examConfig.marked === false)?(
                      <div className='w-full flex flex-row gap-2 justify-end rtl:justify-start mt-5'>
                        <span className={`w-fit`}>{config.config[props.lang].AskQuesIsRight}</span>
                        <div className='flex flex-row-revers rtl:flex-row justify-start -mt-2'>
                          <div className="flex flex-row justify-between w-fit border rounded-lg">
                            <svg onClick={()=>aplayScore(1, essayList[currentEssayQues])} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 px-2 cursor-pointer">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                            <div className="border-l h-full"></div>
                            <svg onClick={()=>aplayScore(0, essayList[currentEssayQues])} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 px-2 cursor-pointer">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                          {
                            (essayIsAnswerd[currentEssayQues] === true)?(
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 border rounded-full mx-3 bg-green-400 stroke-white mt-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                            ):(
                              (essayIsAnswerd[currentEssayQues] === false)?(
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 border rounded-full mx-3 bg-red-400 stroke-white mt-1">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              ):''
                            )
                          }
                        </div>
                      </div>
                    )
                    :(
                      <div className='w-full flex flex-row gap-2 justify-end mt-5'>
                        <div className='flex flex-row-revers justify-start gap-2 relative'>
                          <div className="flex w-fit flex-row justify-center items-center gap-3">
                              <span className={`w-fit`}>{config.config[props.lang].yourScore}</span>
                              <div className="flex flex-col gap-0 divide-y border border-gray-300 rounded-lg">
                                <button onClick={() => aplayScore(score+1, essayList[currentEssayQues])} className='w-7 h-7 shrink-0 justify-center text-center flex items-center content-center justify-items-center font-[Almarai]'>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                  </svg>
                                </button>
                                <div type="number" className='w-7 h-7 shrink-0  justify-center text-center flex items-center content-center justify-items-center font-[Almarai]'>{((currentEssayScore[currentEssayQues] && score >=0)?currentEssayScore[currentEssayQues]:'0')}</div>
                                <button onClick={() => aplayScore(score-1, essayList[currentEssayQues])} className='w-7 h-7 shrink-0 justify-center text-center flex items-center content-center justify-items-center font-[Almarai]'>
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
                                  </svg>
                                </button>
                              </div>
                              <div className={``}>
                              {(examList?.lang == 'ar')?'\/':'/'}
                              </div>
                              <div className={`w-7 h-7 shrink-0 border border-gray-300 rounded-lg justify-center text-center flex items-center content-center justify-items-center font-[Almarai]`}>{essayList[currentEssayQues]?.QuesScore}</div>
                            </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>) : ''}
              {
                (modal === true) ? (
                  <div  className="absolute z-[60] bg-black bg-opacity-40 w-full h-screen left-0 top-0 flex justify-center items-center">
                    <div className="w-11/12 h-fit mx-h-[85vh] gap-2 bg-white rounded-xl p-3 flex flex-col justify-end">
                    <div className="flex w-full flex-row-reverse justify-between">
                      <button onClick={() => hideQuestion()} className="flex w-fit shrink-0 justify-end left-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 fill-primary stroke-white">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                      <Title bookmark={false} quesNumber={essayList[currentEssayQues].key+1} question={essayList[currentEssayQues]} />
                    </div>
                      <div>
                        <ResizeImage size="rem" type="modelAnswer" text={essayList[currentEssayQues].QuesTxt} />
                      </div>
                    </div>
                  </div>
                ) : ''
              }
              <div className="w-full flex flex-row justify-between px-1 sm:px-2">
                <button className={`select-none gap-2 flex flex-row bg-white text-[#001670] text-center py-1 rounded-full ${(currentEssayQues === 0) ? 'opacity-25' : ''}`} 
                  disabled={(currentEssayQues === 0 ) ? true : false} 
                  onClick={() => backQues()}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6}`}>
                    <path className="rtl:hidden" strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    <path className="ltr:hidden" strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                  </svg>
                  <span className=" font-['roman'] rtl:font-['Droid']">{config.config[props.lang].prev}</span>
                </button>
                <button 
                  className={`select-none gap-2 flex flex-row bg-white text-[#001670] text-center py-1 rounded-full ${(currentEssayQues === (essayList.length - 1) || activeNext === false || currentEssayScore[currentEssayQues] == null) ? 'opacity-25' : ''}`} 
                  disabled={(currentEssayQues === (essayList.length - 1) || (activeNext === false) || currentEssayScore[currentEssayQues] == null) ? true : false} 
                  onClick={() => nextQues()}
                >
                  <span className=" font-['roman'] rtl:font-['Droid']">{config.config[props.lang].next}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 }`}>
                    <path className="rtl:hidden" strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    <path className="ltr:hidden" strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                  </svg>
                </button>
              </div>
              <div className="w-full flex flex-row  justify-center flex-wrap gap-3  px-2 py-5">
                {
                  essayList.sort((a, b) => a.key - b.key).map((element, index) => (

                    <div
                      key={index}
                      className={`w-10 h-10 relative select-none ${(currentEssayQues === index) ? 'border-gray-400 text-gray-400' : 'text-gray-400 bg-white'}
                               font-['roman'] rtl:font-['ArabicEqu'] border rounded-md flex  justify-center items-center align-middle`}
                    >
                      {essayList[index].key+1}
                    </div>

                  ))
                }
              </div>
              {
                (essayIsAnswerd.length === essayList.length && essayIsAnswerd.every(element => element !== null))?(
                  <button onClick={() =>
                    {
                      setActiveScreen(false)
                      dispatch(setBriefReport(true))
                    }
                  } className="w-52 sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']">
                    {config.config[props.lang].SubmeitEssay}
                  </button>
                ):''
              }
              <div className="h-10"></div>
            </div>
          ) : ''
      }
    </>
  )
}