/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import {
    BackNavBar,
    StartExam,
    Modal,
    Pagination,
    Controllers,
    MCQ,
    Essay,
    Paragraph,
    FullReport,
    EssayModal,
    Timer,
    EssayEvaluation,
    Login,
    Title,
    ResizeImage
} from "../components";
import {
    setBlur,
    setModal,
    setExamCounter,
    setBriefReport,
    setModalBody,
    setModalTitle,
    setModalBotton,
    setTimer,
    setExamConfig,
    setStartExam,
    setModalSubTitle,
    setUserAnswer,
    setBookmark,
    setQuestionsList,
    setCurrentQues,
    setFullReport
} from '../store/actions';
import config from "../sql/config.json";
import { v4 as uuidv4 } from "uuid";
import { useIsAuthenticated } from 'react-auth-kit'

export const ExamReport = (props) => {

    const params = useParams()
    const navigate = useNavigate();
    const ae = props.exam
    const isAuthenticated = useIsAuthenticated()
    const dispatch = useDispatch();
    const [exams, setExams] = useState(ae)
    const { lang, currentQues, startExam, blur, modal, examCounter, briefReport, fullReport, userAnswer, actualNumber, essayModal, examConfig } = useSelector(state => state.userAppReducer);
    const [questions, setQuestions] = useState(exams)
    const [isLoading, setIsLoading] = useState(true)
    const [reportMode, setReportMode] = useState(window.location.search)
    const [fullScoreMain, setFullScoreMain] = useState(0)
    const [fullUserScore, setFullUserScore] = useState(0)
    const [userIfAnswer, setUserIfAnswer] = useState(false)
    const [modelEssayEvaluation, setModelEssayEvaluation] = useState(false)
    const [activeLogin, setActiveLogin] = useState((isAuthenticated() === false && localStorage.getItem("userLogin") && localStorage.getItem("userLogin") === false) ? true : false);
    useEffect(() => {
        // console.log(exams);
        dispatch(setBlur(false))
        dispatch(setBriefReport(true))
        dispatch(setCurrentQues(0))
        dispatch(setStartExam(false))
        dispatch(setModal(false))
        dispatch(setQuestionsList(exams.questions))
        dispatch(setUserAnswer(exams.userAnswer ? exams.userAnswer : []))
        dispatch(setTimer((localStorage.getItem("userTime_" + params.id) !== null) ? localStorage.getItem("userTime_" + params.id) - exams.duration : exams.duration))
        // @ts-ignore
        window.bookmark = [];
        // @ts-ignore
        window.report = [];
        // @ts-ignore
        window.time = exams.duration;
        let a = localStorage.getItem("userTime_" + params.id);
        let b = exams.duration;
        if (a > b) {
            localStorage.setItem("userTime_" + params.id, 0);
        }
        // @ts-ignore
        window.userTime = exams.userAnswer ? exams.userAnswer.length : 0;
        setUserIfAnswer(exams.userAnswer ? exams.userAnswer.length : 0)
        startTimerMain()
        // @ts-ignore
        dispatch(setExamCounter(questions.questions.length))
        // @ts-ignore
        dispatch(setStartExam(false))
        // @ts-ignore
        dispatch(setModalBotton([...buttonsListForModelFinish]))
        // @ts-ignore
        dispatch(setModalTitle(''))
        // @ts-ignore
        dispatch(setModalSubTitle(config.config[exams.lang].takenBody))
        // @ts-ignore
        dispatch(setModalBody(''))
        // @ts-ignore
        // dispatch(setBlur(true))
        // // @ts-ignore
        // dispatch(setModal(true))
        // @ts-ignore
        dispatch(setExamConfig(questions))
        setTimeout(() => {
            // @ts-ignore
            setIsLoading((document.readyState === 'complete') ? 0 : 1)
        }, 100);
        window.bookmark = [];
        window.report = [];
        checkBookMark()
        if (params.index) {
            dispatch(setModal(false))
            dispatch(setStartExam(false))
            dispatch(setBriefReport(false))
            dispatch(setFullReport(true))
            dispatch(setBlur(false))
            dispatch(setCurrentQues(parseInt(params.index) - 1))
        }
    }, [params.id])

    const startTimeFrom = () => {
        window.timer = true
    }

    const closeModel = () => {
        // @ts-ignore
        dispatch(setBlur(false))
        // @ts-ignore
        dispatch(setModal(false))
        if (exams.timer === true) {
            startTimeFrom()
        }
    };

    const buttonsListForModelFinish = [
        {
            text: config.config[exams.lang].complete,
            color: 'text-white', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-primary', //tail wind css border
            background: 'bg-primary',
            action: closeModel,
        }, {
            text: config.config[exams.lang].Retake,
            color: 'text-[#151048]', // tail wind css color
            border: 'border', //tail wind css border
            borderColor: 'border-gray-300', //tail wind css border
            background: 'bg-gray-300',
            action: () => {
                localStorage.removeItem("userTime_" + params.id);
                localStorage.removeItem("userAnswer_" + params.id);
                window.location.reload()
            }
        }
    ];

    const checkBookMark = () => {
        // @ts-ignore
        // window.report = JSON.parse(localStorage.getItem('report'))
        // if ((window.report).length > 0) {
        //     // @ts-ignore
        //     setExams(window.report[0])
        //     // @ts-ignore
        //     setQuestions(window.report[0])
        //     // @ts-ignore
        //     // dispatch(setExamCounter(window.report[0].questions.length))
        //     // @ts-ignore
        //     // dispatch(setUserAnswer(window.report[0].userAnswer))
        //     // @ts-ignore
        //     window.userTime = window.report[0].userTime

        //     // @ts-ignore
        //     dispatch(setExamConfig(window.report[0]))

        //     // @ts-ignore
        //     dispatch(setBlur(false))

        //     // @ts-ignore
        //     dispatch(setModal(false))

        //     // @ts-ignore
        //     dispatch(setBriefReport(true))

        //     // @ts-ignore
        //     dispatch(setStartExam(false))

        //     setIsLoading(false)
        // } else {
        //     setTimeout(() => {
        //         checkBookMark()
        //     }, 1500);
        // }
    }

    // @ts-ignore
    window.addBookMark = function (object) {
        var item = {
            "currentQues": currentQues,
            "QuesID": object.QuesID,
            "id": object.id,
            "lang": exams.lang,
            "QuesType": object.QuesType,
            "QuesSubType": object.QuesSubType,
            "QuesScore": object.QuesScore,
            "QuesHeadTxt": object.QuesHeadTxt,
            "QuesTxt": (object.QuesTxt).replaceAll('image/inlineImages', (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
            "QuesShowAns1": (object.QuesType !== "Paragraph") ? ({
                "txt": (object.QuesShowAns1.txt).replaceAll('image/inlineImages', (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
                "status": object.QuesShowAns1.status
            }) : '',
            "QuesAns": (object.QuesType !== "Paragraph") ? (object.QuesAns.map(e => {
                return {
                    "txt": e.txt.replace(/image\/inlineImages/g, (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
                    "buttons": e.buttons
                }
            }
            )) : '',
            "QuesRightAns": object.QuesRightAns,
            "index": object.index,
            "Answer": (userAnswer[currentQues]) ? true : false,
            "userAnswer": (userAnswer[currentQues]) ? userAnswer[currentQues].answer : null,
            "numeric_type": object.numeric_type,
            "QuesUsrAns": {
                "isRightAnswer": (userAnswer[currentQues] && userAnswer[currentQues].answer === userAnswer[currentQues].right) ? true : false,
                "userAns": (userAnswer[currentQues]) ? userAnswer[currentQues].answer : null,
                "modelAns": (object.QuesType === 'mcq') ? (
                    getAnswerFromRight(object.QuesAns, object.QuesRightAns, (userAnswer[currentQues]) ? userAnswer[currentQues] : null)
                ) : (
                    (object.QuesType === 'essay') ? (
                        object.QuesShowAns1.txt
                    ) : null
                )
            },
            "list": (object.QuesType === "Paragraph") ? ParagraphAnswers(object.list) : '',
            "breadcrumbs": exams.breadcrumbs,
            "showAnserButton": exams.showAnserButton
        }

        // @ts-ignore
        void 0 !== window.Android && window.Android.addBookMarkToAndroid(JSON.stringify(item));
    };

    const ParagraphAnswers = (list) => {
        const item = [];
        list.map((e, index) => {
            let a = {
                "currentQues": currentQues,
                "QuesID": e.QuesID,
                "id": e.id,
                "lang": exams.lang,
                "QuesType": e.QuesType,
                "QuesSubType": e.QuesSubType,
                "QuesScore": e.QuesScore,
                "QuesHeadTxt": e.QuesHeadTxt,
                "QuesTxt": (e.QuesTxt).replaceAll('image/inlineImages', (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
                "QuesShowAns1": (e.QuesType !== "Paragraph") ? ({
                    "txt": (e.QuesShowAns1.txt).replaceAll('image/inlineImages', (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
                    "status": e.QuesShowAns1.status
                }) : '',
                "QuesAns": (e.QuesType !== "Paragraph") ? (e.QuesAns.map(e => {
                    return {
                        "txt": e.txt.replace(/image\/inlineImages/g, (window.location.pathname).split('index.html')[0] + "image/inlineImages"),
                        "buttons": e.buttons
                    }
                }
                )) : '',
                "QuesRightAns": e.QuesRightAns,
                "index": e.index,
                "Answer": (userAnswer[currentQues] && userAnswer[currentQues][index]) ? true : false,
                "userAnswer": (userAnswer[currentQues] && userAnswer[currentQues][index] && userAnswer[currentQues][index][0].answer) ? userAnswer[currentQues][index][0].answer : null,
                "numeric_type": e.numeric_type,
                "QuesUsrAns": {
                    "isRightAnswer": (userAnswer[currentQues] && userAnswer[currentQues][index] && userAnswer[currentQues][index][0].answer === userAnswer[currentQues][index][0].right) ? true : false,
                    "userAns": (userAnswer[currentQues] && userAnswer[currentQues][index]) ? userAnswer[currentQues][index][0].answer : null,
                    "modelAns": (e.QuesType === 'mcq') ? (
                        getAnswerFromRight(e.QuesAns, e.QuesRightAns, null)
                    ) : (
                        (e.QuesType === 'essay') ? (
                            e.QuesShowAns1.txt
                        ) : null
                    )
                },
                "breadcrumbs": exams.breadcrumbs,
                "showAnserButton": exams.showAnserButton
            }
            item.push(a)
        })
        return item
    }

    // @ts-ignore
    window.removeBookMark = function (item) {
        // @ts-ignore
        if (window.bookmark && window.bookmark[0] && window.bookmark[0].questions) {
            // @ts-ignore
            const x = window.bookmark[0].questions.splice(item, 1);
        }
        // @ts-ignore
        void 0 !== window.Android && window.Android.removeBookMarkToAndroid(JSON.stringify(item));
        // @ts-ignore
    };

    // @ts-ignore
    window.getBookMark = function () {
        // @ts-ignore
        return window.bookmark || [];
    };


    const toggleModel = () => {
        // @ts-ignore
        dispatch(setBlur(!blur))
        // @ts-ignore
        dispatch(setModal(!modal))
    };

    const CloseModel = () => {
        // @ts-ignore
        dispatch(setBlur(false))
        // @ts-ignore
        dispatch(setModal(false))
    };

    const ViewBriefReport = () => {
        localStorage.removeItem("userTime_" + params.id)
        localStorage.removeItem("userAnswer_" + params.id)
        dispatch(setBlur(false))
        // @ts-ignore
        dispatch(setModal(false))
        // @ts-ignore
        setModelEssayEvaluation(true)
        // @ts-ignore
        window.timer = false
    };

    const finishExam = () => {
        // @ts-ignore
        dispatch(setModalBotton([
            {
                text: config.config[questions.lang].yes,
                color: 'text-white', // tail wind css color
                border: 'border', //tail wind css border
                borderColor: 'border-primary', //tail wind css border
                background: 'bg-primary',
                action: ViewBriefReport,
            },
            {
                text: config.config[questions.lang].no,
                color: 'text-[#151048]', // tail wind css color
                border: 'border', //tail wind css border
                borderColor: 'border-gray-500', //tail wind css border
                background: 'bg-gray-200',
                action: CloseModel,
            }
        ]))

        // @ts-ignore
        dispatch(setModalTitle(questions.title))
        // @ts-ignore
        dispatch(setModalSubTitle(config.config[questions.lang].ConfirmFinishExam))
        toggleModel()
    }

    const getAnswerFromRight = (answers, rightAnswer, userNswer) => {
        let a = {
            'ar': ['ا', 'ب', 'جـ', 'د', 'و',],
            'en': ['a', 'b', 'c', 'd', 'e']
        }
        return answers[a[exams.lang].indexOf(rightAnswer)].txt
    }

    const mathConfig = {
        loader: {
            load: ['ui/lazy'],
        },
        tex: {
            inlineMath: [['$', '$'], ['\\(', '\\)'], ['\\\(', '\\\)']]
        }
    };

    const userRight = () => {
        var num = 0;
        if (userAnswer) {
            Object.entries(userAnswer).map(e => {
                if (e["1"] && e["1"].type) {
                    num = (exams.marked === true) ? num + e["1"].userScore : num + 1
                } else {
                    if (e["1"]) {
                        Object.values(e["1"]).flatMap(a => {
                            if(a !== null){
                                a.map(q => {
                                    if (q != null) {
                                        num = (exams.marked === true) ? num + q.userScore : num + 1
                                    }
                                })
                            }
                        });
                    }
                }
            })
        }
        return num
    }

    function startTimerMain() {
        const duration = exams.duration;

        const pad = (n) => n < 10 ? `0${n}` : n;
        const h = Math.floor(duration / 3600);
        const m = Math.floor(duration / 60) - (h * 60);
        const s = Math.floor(duration - h * 3600 - m * 60);
        // @ts-ignore
        window.time = pad(h) + ":" + pad(m) + ":" + pad(s);

        // @ts-ignore
        dispatch(setTimer(pad(h) + ":" + pad(m) + ":" + pad(s)))

    }

    if (isLoading === true) {
        return (<div dir={(lang === "ar") ? 'rtl' : 'ltr'} className={`w-full h-screen  p-0 bg-gray-100 flex flex-col items-center content-center justify-items-center font-[Almarai]'} md:max-w-[875px] md:m-auto `}>
            <BackNavBar />
            <div className='flex w-screen h-full bg-[#001670] bg-opacity-60 fixed top-12 left-0 justify-center items-center align-middle content-center self-center z-50'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10 animate-spin stroke-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </div>
        </div>
        )
    } else {
        return (
            <div dir={(questions.lang === "ar") ? 'rtl' : 'ltr'} className={`w-full h-full p-0 bg-gray-100 flex flex-col font-[Almarai] md:max-w-[875px] md:m-auto `}>
                <BackNavBar />
                <div dir={(questions.lang === "ar") ? 'rtl' : 'ltr'} className={`bg-white w-full h-full ${(activeLogin) ? 'z-0 blur-sm' : ''}`}>
                    {
                        (fullReport === false) ? (
                            <Timer active={exams.timer} />
                        ) : (<></>)
                    }
                    <div className={`min-h-screen px-2 sm:px-10 pb-5 ${(briefReport === true) ? 'overflow-hidden' : 'overflow-auto'}`}>
                        {
                            (questions.questions[currentQues].QuesType === 'Paragraph') ? (
                                <div className="w-full py-4 flex flex-col">
                                    <div className="w-full flex flex-col justify-start gap-4 relative">
                                        <Title
                                            quesNumber={currentQues + 1}
                                            question={questions.questions[currentQues]}
                                            bookmark={false}
                                        />

                                        <div
                                            className={`h-fit max-h-[27vh] overflow-auto font-['roman'] rtl:font-['Droid'] border rounded-t-md rounded-b-[20px] border-gray-300 p-2 bg-white`}
                                        >
                                            <ResizeImage
                                                size="rem"
                                                type="paragraph"
                                                text={questions.questions[currentQues].QuesTxt}
                                            />
                                        </div>

                                        <div
                                            id="paragraphBody"
                                            className={`h-fit max-h-[50vh] overflow-auto border-t`}
                                        >
                                            <Paragraph essayKey={uuidv4()} index={questions.questions[currentQues].QuesID} lang={questions.lang} key={currentQues} quesNumber={currentQues + 1} question={questions.questions[currentQues]} />
                                            <div className="flex flex-col h-fit justify-center">
                                                <Controllers lang={questions.lang} />
                                                <Pagination number={examCounter} type={exams.questions} />
                                                {
                                                    (fullReport !== true) ? (
                                                        <button onClick={() => finishExam()} className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']">
                                                            {config.config[questions.lang].finishExam}
                                                        </button>
                                                    ) : (
                                                        <button onClick={() => dispatch(setBriefReport(true))} className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']">
                                                            {config.config[questions.lang].briefRprtBtn}
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={`${(blur === true) ? 'blur-sm' : ''} `}>
                                    <div className='flex flex-col justify-between min-h-[95vh]'>
                                        <div>
                                            <div className="w-full pb-4 px-1 flex flex-col">
                                                <div>
                                                    {
                                                        (questions.questions[currentQues].QuesType === 'mcq') ? (
                                                            <MCQ id={params.id} lang={questions.lang} key={currentQues} quesNumber={currentQues + 1} question={questions.questions[currentQues]} answers={questions.questions[currentQues].QuesAns} />
                                                        ) : (
                                                            <Essay lang={questions.lang} key={currentQues} quesNumber={currentQues + 1} question={questions.questions[currentQues]} answers={questions.questions[currentQues].QuesAns} />
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col h-fit justify-center">
                                            <Controllers lang={questions.lang} />
                                            <Pagination number={examCounter} type={exams.questions} />
                                            {
                                                (fullReport !== true) ? (
                                                    <button onClick={() => finishExam()} className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']">
                                                        {config.config[questions.lang].finishExam}
                                                    </button>
                                                ) : (
                                                    <button onClick={() => dispatch(setBriefReport(true))} className="w-full sm:w-52 m-auto border-primary text-white bg-primary border text-center py-2 px-4 rounded-full  font-['roman'] rtl:font-['Droid']">
                                                        {config.config[questions.lang].briefRprtBtn}
                                                    </button>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        <EssayEvaluation active={(modelEssayEvaluation === true && fullReport === false)} lang={exams.lang} exam={exams}/>
                        <FullReport action={() => { }} retray={2} lang={exams.lang} active={briefReport} message={config.config[exams.lang].ExcelentRsltMsg} total={actualNumber} right={{ number: userRight(), color: ['#C0E7BA', '#2FAE1D'] }} exam={exams}/>
                        <StartExam active={startExam} action="" lang={questions.lang} timer={{ active: questions.timer, time: questions.duration }} number="" title={questions.title} exam={exams}/>
                        {/* finish Model */}
                        <Modal active={modal} lang={exams.lang} />
                        <EssayModal active={essayModal} lang={exams.lang} exam={exams}/>
                        <div className="h-10" />
                    </div>
                </div>
            </div>
        );
    }
}

